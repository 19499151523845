import { isObject } from 'lodash';
import { Cell } from 'react-table';

import { getAvailableOptions } from '../helpers';
import {
  TAdditionalEditableOptions,
  TExtendedColumnWithAdditionalFields,
} from '../types';

export const hasEditParams = <Data extends object>(
  column: unknown,
): column is Cell<Data>['column'] & TAdditionalEditableOptions<Data> =>
  isObject(column) && 'editParams' in column;

const selectSeparator = ',';

export function prepareDataForEditInput<Data extends object>(
  allColumns: (TExtendedColumnWithAdditionalFields<Data> & {
    options?: string[];
  })[],
  data: Data[],
): Record<
  keyof Data,
  TExtendedColumnWithAdditionalFields<Data> & {
    options?: string[];
  }
> {
  const availableFilters = allColumns.reduce((acc, current) => {
    return [
      ...acc,
      {
        ...current,
        options:
          current.options ||
          getAvailableOptions(data, current, selectSeparator),
      },
    ];
  }, []);

  const preparedData = availableFilters.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr }),
    {},
  );

  return preparedData;
}

export const getIsEditable = <Data extends object>(
  cell: Cell<Data> & {
    column: TExtendedColumnWithAdditionalFields<Data>;
  },
) => {
  const { column } = cell;

  if (!hasEditParams(column)) {
    return false;
  }

  const { editParams } = column;

  if (typeof editParams?.editable === 'function') {
    return editParams.editable(cell);
  }
  return editParams?.editable;
};

export const joinArrayWithTabsAndNewlines = (array, n) => {
  return array
    .map((element, index) => {
      return element + (index % n === n - 1 ? '\n' : '\t');
    })
    .join('');
};

export const copyTableRows = (event, length) => {
  const selection = document.getSelection();
  const selectionIntoArray = selection.toString().split('\n');
  event.clipboardData.setData(
    'text/plain',
    joinArrayWithTabsAndNewlines(selectionIntoArray, length),
  );
  event.preventDefault();
};

export const getParentDataRowId = (element) => {
  let parentNode = element.parentNode;

  if (element.hasAttribute('data-row-id')) {
    return element.getAttribute('data-row-id');
  }

  while (parentNode !== null) {
    if (
      'hasAttribute' in parentNode &&
      parentNode.hasAttribute('data-row-id')
    ) {
      return parentNode.getAttribute('data-row-id');
    }
    parentNode = parentNode.parentNode;
  }

  return null;
};

export const onCopy = async <Data extends object>(data: Data) => {
  const copiedData = Object.entries(data)
    .filter(([key]) => key !== 'actions')
    .map(([, value]) => String(value))
    .join('\t');

  try {
    await navigator.clipboard.writeText(copiedData);
  } catch (err) {
    console.error('Failed to copy data to clipboard:', err);
  }
};

/**
 * Transforms string representations of `true`, `false`, `null`, and `undefined`
 * into their corresponding primitive values. Leaves other values unchanged.
 *
 * @param value - The value to be prepared.
 * @returns The transformed value if it matches any known strings, otherwise the original value.
 */
export const prepareValue = (value: unknown): unknown => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'null':
      return null;
    case 'undefined':
      return undefined;
    default:
      return value;
  }
};
