import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ChangeCardPin = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M23 14.857v5.264M18 18.493l5 1.628M19.908 24.38L23 20.12M26.092 24.38L23 20.12M28 18.493l-5 1.628M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26.337 23.271l-3.721-3.721M27.302 17.166l-4.687 2.384M21.79 14.353l.826 5.199M17.418 18.726l5.198.826M20.231 24.238l2.384-4.687M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26.337 23.271l-3.721-3.721M27.302 17.166l-4.687 2.384M21.79 14.353l.826 5.199M17.418 18.726l5.198.826M20.231 24.238l2.384-4.687M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M23 14.857v5.264M18 18.493l5 1.628M19.908 24.38L23 20.12M26.092 24.38L23 20.12M28 18.493l-5 1.628M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ChangeCardPin;
