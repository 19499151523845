import './index.css';
import { initApp } from './utils/initApp';

window.NOTIFICATIONS_SHAPER_UI = {
  ...window.NOTIFICATIONS_SHAPER_UI,
  render: (node: Element) => initApp(node),
};

// is rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}
