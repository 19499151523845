import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Password = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 12v5M11 15l5 2M13 21l3-4M19 21l-3-4M21 15l-5 2M5 14.349V7a1 1 0 011-1h20a1 1 0 011 1v7.346c0 10.523-8.914 14.009-10.692 14.6a.943.943 0 01-.615 0C13.913 28.358 5 24.875 5 14.35z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Password;
