import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  Notification,
  useTableData,
  Modal,
  useModal,
  IconButton,
} from 'react-ui-kit-exante';

import { PageContext } from 'contexts/PagesContext';

import { Notifications, PAGES } from '../../constants/common';
import { SettingsContext } from '../../contexts/SettingsContext';
import { EVENT_CREATE_PATH, EVENTS_PATH } from '../../routes';
import { EventsService } from '../../services/shaper/events';
import { deleteEventReq } from '../../services/shaper/events/events.requests';
import { getFiltersOptions } from '../../services/shaper/events/events.service';
import { EventsState, TEvent } from '../../services/shaper/events/events.types';
import { calculateCountOfPages } from '../../utils';

import { getColumns, hiddenColumns } from './columns';
import {
  defaultFilterState,
  FiltersState,
  getAdditionalFilters,
  getDefaultFilters,
} from './filters/filters';

export const Events: FC = () => {
  const { brandings } = useContext(SettingsContext);
  const navigate = useNavigate();
  const deleteModal = useModal();

  const [filterOptions, setFilterOptions] =
    useState<FiltersState>(defaultFilterState);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingData, setDeletingData] = useState<TEvent | null>(null);
  const getEvents = useCallback(
    ({ params, filtersParams }: any) =>
      EventsService.getEvents(params, filtersParams),
    [],
  );

  const { pageFrom, setPageFrom, setFilterParams } = useContext(PageContext);

  useEffect(() => {
    if (pageFrom !== PAGES.EVENTS) {
      setPageFrom(PAGES.EVENTS);
    }
  }, []);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const filters = await getFiltersOptions(brandings);
        setFilterOptions(filters);
      } catch (error: any) {
        Notification.error(error?.message);
      }
    };
    getOptions();
  }, []);

  const tableDataArgs = useMemo(
    () => ({
      data: {
        onFetch: getEvents,
        onFailure: (e: Error) => Notification.error({ title: e.message }),
      },
      filters: { getDefaultFilters },
    }),
    [getEvents],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setSorting,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
  } = useTableData<EventsState>(tableDataArgs);

  const filterQueryString = window.location.search;

  useEffect(() => {
    setFilterParams(filterQueryString);
  }, [filters]);

  const total = data?.total || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useMemo(
    () => getColumns(),
    [filterOptions, setFilter, removeFilter],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        filterOptions,
      }),
    [filterOptions, setFilter, removeFilter],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
    }),
    [additionalFilters, resetFilters, filters],
  );

  const displayedColumnKeys = columns
    .filter((column) => !hiddenColumns.includes(column.Header as string))
    .map((column) => column.accessor) as string[];

  const handleRowClick = useCallback(
    ({ id }: any) => {
      navigate(`${EVENTS_PATH}/${id}`);
    },
    [navigate],
  );

  const openNewEventForm = () => {
    navigate(EVENT_CREATE_PATH);
  };

  const deleteClickHandler = (row: TEvent) => {
    deleteModal.onOpen();
    setDeletingData(row);
  };

  const onCloseDeleteModalHandler = useCallback(() => {
    deleteModal.onClose();
    setDeletingData(null);
    setIsDeleting(false);
  }, []);

  const onDeleteConfirmHandler = useCallback(async () => {
    const successMessage = `${Notifications.EventType} is ${Notifications.SuccessDelete}`;

    if (deletingData) {
      setIsDeleting(true);
      try {
        await deleteEventReq(deletingData.name);
        Notification.success({ title: successMessage });
      } catch (error: any) {
        Notification.error(error?.message);
      } finally {
        onCloseDeleteModalHandler();
        await fetchData();
      }
    }
  }, [deletingData]);

  return (
    <>
      <Table
        title="Events"
        titleSize={1}
        onAdd={openNewEventForm}
        columns={columns}
        data={data?.result || []}
        isLoading={isLoading}
        showTableInfo
        tableId="notification-shaper-events"
        hasFilters
        hasPagination
        manualSortBy
        defaultSortBy={[]}
        onSort={setSorting}
        filteringProps={filteringProps}
        handleRowClick={handleRowClick}
        displayedColumnKeys={displayedColumnKeys}
        rowActions={{
          show: true,
          additionalActions: [
            {
              label: (
                <IconButton
                  iconSize={24}
                  iconColor="action"
                  iconName="DeleteIcon"
                  data-test-id="notifications-module__button--delete-event"
                  title="Delete event"
                />
              ),
              onClick: deleteClickHandler,
              title: 'Delete event',
              width: 100,
            },
          ],
        }}
        serverPaginationProps={{
          pageSize: limit,
          setPage,
          setPageSize: setLimit,
          pageIndex: page,
          total,
          pageCount,
        }}
        isFlexLayout
        showScrollbar
      />
      <Modal
        isOpened={deleteModal.isOpened}
        onClose={onCloseDeleteModalHandler}
        title="Are you sure?"
        confirmButton={{
          confirmButtonIsDisabled: isDeleting,
          handleConfirm: onDeleteConfirmHandler,
        }}
        isLoading={isDeleting}
      >
        <p>
          Are you sure you want to delete the event
          <br />
          <b>{deletingData?.name}</b>?
        </p>
      </Modal>
    </>
  );
};
