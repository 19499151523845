import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const FilterSelect = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M29.333 4H2.667l10.666 12.613v8.72L18.667 28V16.613L29.333 4z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect x={16} width={16} height={14} rx={7} fill="currentColor" />
          <path
            d="M23.95 10.09c-.477 0-.902-.082-1.275-.247a2.176 2.176 0 01-.88-.684 1.719 1.719 0 01-.328-1.01h1.393c.009.16.06.3.157.422.098.12.228.212.39.278.162.066.344.1.546.1.211 0 .398-.038.56-.112a.918.918 0 00.38-.31.782.782 0 00.137-.457.765.765 0 00-.147-.464.94.94 0 00-.415-.32 1.616 1.616 0 00-.64-.114h-.61V6.155h.61c.21 0 .394-.036.553-.108a.892.892 0 00.378-.3.78.78 0 00.134-.455.785.785 0 00-.445-.725 1.06 1.06 0 00-.485-.106c-.188 0-.36.034-.515.103a.9.9 0 00-.374.284.749.749 0 00-.15.435h-1.326c.006-.377.114-.71.323-.997.208-.288.49-.513.843-.675a2.845 2.845 0 011.205-.246c.452 0 .847.082 1.186.246.339.164.602.386.79.665.189.277.283.588.28.933.003.367-.111.672-.341.917-.229.245-.526.401-.892.467v.051c.481.062.848.23 1.1.502.253.27.379.61.376 1.016.003.373-.105.704-.322.994-.216.29-.513.518-.892.684a3.22 3.22 0 01-1.304.25z"
            fill="currentColor"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M29.333 4H2.667l10.666 12.613v8.72L18.667 28V16.613L29.333 4z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect x={16} width={16} height={14} rx={7} fill="currentColor" />
          <path
            d="M23.95 10.09c-.477 0-.902-.082-1.275-.247a2.176 2.176 0 01-.88-.684 1.719 1.719 0 01-.328-1.01h1.393c.009.16.06.3.157.422.098.12.228.212.39.278.162.066.344.1.546.1.211 0 .398-.038.56-.112a.918.918 0 00.38-.31.782.782 0 00.137-.457.765.765 0 00-.147-.464.94.94 0 00-.415-.32 1.616 1.616 0 00-.64-.114h-.61V6.155h.61c.21 0 .394-.036.553-.108a.892.892 0 00.378-.3.78.78 0 00.134-.455.785.785 0 00-.445-.725 1.06 1.06 0 00-.485-.106c-.188 0-.36.034-.515.103a.9.9 0 00-.374.284.749.749 0 00-.15.435h-1.326c.006-.377.114-.71.323-.997.208-.288.49-.513.843-.675a2.845 2.845 0 011.205-.246c.452 0 .847.082 1.186.246.339.164.602.386.79.665.189.277.283.588.28.933.003.367-.111.672-.341.917-.229.245-.526.401-.892.467v.051c.481.062.848.23 1.1.502.253.27.379.61.376 1.016.003.373-.105.704-.322.994-.216.29-.513.518-.892.684a3.22 3.22 0 01-1.304.25z"
            fill="currentColor"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M29.333 4H2.667l10.666 12.613v8.72L18.667 28V16.613L29.333 4z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect x={16} width={16} height={14} rx={7} fill="currentColor" />
          <path
            d="M23.95 10.09c-.477 0-.902-.082-1.275-.247a2.176 2.176 0 01-.88-.684 1.719 1.719 0 01-.328-1.01h1.393c.009.16.06.3.157.422.098.12.228.212.39.278.162.066.344.1.546.1.211 0 .398-.038.56-.112a.918.918 0 00.38-.31.782.782 0 00.137-.457.765.765 0 00-.147-.464.94.94 0 00-.415-.32 1.616 1.616 0 00-.64-.114h-.61V6.155h.61c.21 0 .394-.036.553-.108a.892.892 0 00.378-.3.78.78 0 00.134-.455.785.785 0 00-.445-.725 1.06 1.06 0 00-.485-.106c-.188 0-.36.034-.515.103a.9.9 0 00-.374.284.749.749 0 00-.15.435h-1.326c.006-.377.114-.71.323-.997.208-.288.49-.513.843-.675a2.845 2.845 0 011.205-.246c.452 0 .847.082 1.186.246.339.164.602.386.79.665.189.277.283.588.28.933.003.367-.111.672-.341.917-.229.245-.526.401-.892.467v.051c.481.062.848.23 1.1.502.253.27.379.61.376 1.016.003.373-.105.704-.322.994-.216.29-.513.518-.892.684a3.22 3.22 0 01-1.304.25z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4}>
            <path
              d="M29.333 4H2.667l10.666 12.613v8.72L18.667 28V16.613L29.333 4z"
              stroke="currentColor"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect x={16} width={16} height={14} rx={7} fill="currentColor" />
            <path
              d="M23.95 10.09c-.477 0-.902-.082-1.275-.247a2.176 2.176 0 01-.88-.684 1.719 1.719 0 01-.328-1.01h1.393c.009.16.06.3.157.422.098.12.228.212.39.278.162.066.344.1.546.1.211 0 .398-.038.56-.112a.918.918 0 00.38-.31.782.782 0 00.137-.457.765.765 0 00-.147-.464.94.94 0 00-.415-.32 1.616 1.616 0 00-.64-.114h-.61V6.155h.61c.21 0 .394-.036.553-.108a.892.892 0 00.378-.3.78.78 0 00.134-.455.785.785 0 00-.445-.725 1.06 1.06 0 00-.485-.106c-.188 0-.36.034-.515.103a.9.9 0 00-.374.284.749.749 0 00-.15.435h-1.326c.006-.377.114-.71.323-.997.208-.288.49-.513.843-.675a2.845 2.845 0 011.205-.246c.452 0 .847.082 1.186.246.339.164.602.386.79.665.189.277.283.588.28.933.003.367-.111.672-.341.917-.229.245-.526.401-.892.467v.051c.481.062.848.23 1.1.502.253.27.379.61.376 1.016.003.373-.105.704-.322.994-.216.29-.513.518-.892.684a3.22 3.22 0 01-1.304.25z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default FilterSelect;
