import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const HTTP = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25.25 22a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM17.25 9a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM9.25 22a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M4 19a5 5 0 109 3h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.582 7a5 5 0 10-7.207 6.258L8 22"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 27a5 5 0 10-2.625-9.259L16 9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25.25 22a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM17.25 9a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM9.25 22a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M4 19a5 5 0 109 3h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.582 7a5 5 0 10-7.207 6.258L8 22"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 27a5 5 0 10-2.625-9.259L16 9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25.25 22a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM17.25 9a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM9.25 22a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M4 19a5 5 0 109 3h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.582 7a5 5 0 10-7.207 6.258L8 22"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 27a5 5 0 10-2.625-9.259L16 9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M25.25 22a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM17.25 9a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM9.25 22a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
              fill="currentColor"
            />
            <path
              d="M4 19a5 5 0 109 3h11"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.582 7a5 5 0 10-7.207 6.258L8 22"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24 27a5 5 0 10-2.625-9.259L16 9"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default HTTP;
