import { isAfter, isBefore, isWithinInterval, set } from 'date-fns';
import { isEqual } from 'lodash';

interface IIsWithinIntervalSafeArguments {
  date: Date;
  start?: Date;
  end?: Date;
  ignoreDayAndTime?: boolean;
}

/**
 * Returns true if the given date is within the interval
 *
 * @param date date to check
 * @param start start of the interval
 * @param end end of the interval
 * @param ignoreDayAndTime do not compare 'day' and 'time' parts of the date
 */
export const isWithinIntervalSafe = ({
  date,
  start,
  end,
  ignoreDayAndTime = false,
}: IIsWithinIntervalSafeArguments) => {
  if (!start && !end) {
    return true;
  }

  const clipper = {
    date: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  };

  const dateClipped = ignoreDayAndTime ? set(date, clipper) : date;
  const startClipped = ignoreDayAndTime ? start && set(start, clipper) : start;
  const endClipped = ignoreDayAndTime ? end && set(end, clipper) : end;

  if (!start) {
    return (
      isBefore(dateClipped, endClipped) || isEqual(dateClipped, endClipped)
    );
  }

  if (!end) {
    return (
      isAfter(dateClipped, startClipped) || isEqual(dateClipped, startClipped)
    );
  }

  return isWithinInterval(dateClipped, {
    start: startClipped,
    end: endClipped,
  });
};
