import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Checkbox } from 'react-ui-kit-exante';

import { IFormCheckboxContainerProps } from './FormCheckboxContainer.types';

export const FormCheckboxContainer: FC<IFormCheckboxContainerProps> = ({
  label,
  name,
  noWrap = false,
  disabled = false,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value = false },
  } = useController({
    control,
    name,
  });

  return (
    <Checkbox
      checked={value}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      sx={{
        whiteSpace: noWrap ? 'nowrap' : 'inherit',
      }}
    />
  );
};
