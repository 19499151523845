import { FC, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { CodeEditor, EditorMode } from 'react-ui-kit-exante';

import { ChannelType } from '../../../../constants/common';

import { EditorProps } from './Editor.types';

const DefaultHeight = '770px';

export const Editor: FC<EditorProps> = ({
  name,
  error,
  type = ChannelType.Email,
  disabled,
}) => {
  const formContext = useFormContext();
  const {
    field: { onChange, onBlur: onBlurReactHookForm, value },
    fieldState: { error: fieldError },
  } = useController({
    control: formContext.control,
    name,
  });

  formContext.register(name);

  const onBlur = useCallback(() => {
    onBlurReactHookForm();
  }, [formContext, onBlurReactHookForm]);

  const annotations = useMemo(() => {
    if (error) {
      return [
        {
          row: error.lineno - 1,
          type: 'error',
          text: error.error,
        },
      ];
    }

    if (fieldError) {
      return [
        {
          row: 0,
          type: 'error',
          text: fieldError.message || '',
        },
      ];
    }

    return [];
  }, [error, fieldError]);

  switch (type) {
    case ChannelType.Email:
      return (
        <CodeEditor
          onChange={onChange}
          onBlur={onBlur}
          name="notification-email-editor"
          value={value}
          height={DefaultHeight}
          annotations={annotations}
          readOnly={disabled}
        />
      );
    case ChannelType.Phone:
      return (
        <CodeEditor
          mode={EditorMode.Javascript}
          onChange={onChange}
          onBlur={onBlur}
          name="notification-sms-editor"
          value={value}
          height={DefaultHeight}
          annotations={annotations}
          readOnly={disabled}
        />
      );
    default:
      return null;
  }
};
