import { styled } from 'react-ui-kit-exante';

export const StyledTemplateInfoBlock = styled('dl')`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const StyledTemplateInfoBlockLabel = styled('dt')`
  margin: 0 0 8px;
  padding: 0;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledTemplateInfoBlockValue = styled('dd')`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;
