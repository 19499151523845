import { FC } from 'react';
import { Loader, CodeEditor } from 'react-ui-kit-exante';

import { getJsonString } from '../../../../../utils/getJsonString';

import { StyledMetadataHeader, StyledMetadataLoader } from './Metadata.styled';
import { MetadataProps } from './Metadata.types';

export const Metadata: FC<MetadataProps> = ({ meta, isLoading }) => {
  return (
    <>
      {isLoading && (
        <StyledMetadataLoader>
          <Loader />
        </StyledMetadataLoader>
      )}
      {!isLoading && (
        <>
          <StyledMetadataHeader>Request json</StyledMetadataHeader>
          {meta?.req_json && (
            <CodeEditor
              mode="json"
              name="notification-email-metadata-json"
              value={getJsonString(meta.req_json)}
              readOnly
            />
          )}
          <StyledMetadataHeader>Parameters json schema</StyledMetadataHeader>
          {meta?.params_json_schema && (
            <CodeEditor
              mode="json"
              name="notification-email-metadata-schema"
              value={getJsonString(meta.params_json_schema)}
              readOnly
            />
          )}
          <StyledMetadataHeader>Context</StyledMetadataHeader>
          {meta?.context && (
            <CodeEditor
              mode="json"
              name="notification-email-metadata-json"
              value={getJsonString(meta.context)}
              readOnly
            />
          )}
        </>
      )}
    </>
  );
};
