import { Checkbox as CheckboxUI } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../helpers';

import {
  IControlsProps,
  IIconProps,
  IMessageProps,
  IUICheckboxProps,
} from './types';

export const CheckboxContainerStyled = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SkeletonContainerStyled = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
}));

export const LabelStyled = styled(FormControlLabel, {
  shouldForwardProp: blockNonNativeProps(['checked']),
})<IUICheckboxProps>(({ checked, disabled, theme }) => ({
  margin: 0,
  opacity: disabled ? 0.4 : 1,
  alignItems: 'flex-start',
  '& .MuiFormControlLabel-label': {
    fontSize: '15px',
    fontFamily: theme.font.main,
    lineHeight: '16px',
    color: checked ? theme.color.typo.primary : theme.color.typo.secondary,
    fontWeight: 400,
    '&:hover': {
      color: theme.color.typo.promo,
    },
  },
}));

export const CheckboxMessageStyled = styled('span', {
  shouldForwardProp: blockNonNativeProps(['error', 'warning']),
})<IMessageProps>(({ error, warning, theme }) => {
  const getColorScheme = () => {
    if (warning) {
      return theme.color.typo.warning;
    }
    if (error) {
      return theme.color.typo.radical;
    }
    return theme.color.typo.secondary;
  };

  return {
    padding: '0 12px',
    fontFamily: theme.font.main,
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: '400',
    color: getColorScheme(),
  };
});

export const ControlStyled = styled(CheckboxUI)<IControlsProps>(() => ({
  padding: '0 8px 0 0',
}));

export const IconStyled = styled('span', {
  shouldForwardProp: blockNonNativeProps([
    'checked',
    'color',
    'theme',
    'warning',
    'error',
  ]),
})<IIconProps>(({ checked, color, theme, warning, error }) => {
  const getColorScheme = () => {
    if (warning) {
      return 'warning';
    }
    if (error) {
      return 'radical';
    }
    return color;
  };
  const checkedStatus = checked ? 'checked' : 'nonChecked';
  const styles = theme?.color.checkbox[getColorScheme()][checkedStatus];

  const borderWidth = checked ? 0 : '1px';

  return {
    borderRadius: '4px',
    width: '16px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    background: styles?.bg,
    borderStyle: 'solid',
    borderWidth,
    borderColor: styles?.border.default,
    boxShadow: styles?.boxShadow.default,

    '.MuiFormControlLabel-root:not(.Mui-disabled):hover &': {
      borderColor: styles?.border.hover,
      boxShadow: styles?.boxShadow.hover,
    },
    '.MuiFormControlLabel-root:not(.Mui-disabled) input:focus + &': {
      borderColor: styles?.border.focus,
      boxShadow: styles?.boxShadow.hover,
    },
    '& svg': {
      color: styles?.iconColor,
    },
  };
});
