import { styled } from 'react-ui-kit-exante';

export const StyledViewerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme?.color?.line?.primary}`,
  borderLeft: 'none',
  padding: '16px',
  gap: '16px',
}));

export const StyledViewerDesc = styled('span')(({ theme }) => ({
  color: theme?.color?.typo?.secondary,
  fontSize: '11px',
  marginBottom: '16px',
  display: 'block',
}));

export const StyledEmailViewer = styled('div')``;
