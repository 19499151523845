import { SyntheticEvent, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Autocomplete, ISelectOption } from 'react-ui-kit-exante';

import { IFormMultiSelectContainerProps } from './FormMultiSelectContainer.types';

export const FormMultiSelectContainer = ({
  fullWidth = true,
  label = '',
  name,
  options,
  disabled = false,
  className,
}: IFormMultiSelectContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const multiSelectChangeHandler = useCallback(
    (_: SyntheticEvent, newValues: Array<string | ISelectOption>) => {
      onChange(
        newValues.map((newValue) => {
          if (typeof newValue === 'string') {
            return newValue;
          }
          return newValue.value;
        }),
      );
    },
    [onChange],
  );

  return (
    <Autocomplete
      fullWidth={fullWidth}
      isMultiple
      isOptionEqualToValue={(option, selectedValue) =>
        option.value === selectedValue
      }
      onChange={multiSelectChangeHandler}
      options={options}
      placeholder={label}
      value={value}
      disabled={disabled}
      controlProps={{
        error: Boolean(error),
        message: error?.message,
      }}
      className={className}
    />
  );
};
