import { Menu, MenuItem as MUIMenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContextMenuContainer = styled(Menu)(({ theme }) => ({
  '.MuiMenu-list': {
    padding: 0,
    backgroundColor: theme.color.dropdown.bg.default,
    borderRadius: '8px',
  },
}));

export const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
  padding: 8,
  fontFamily: theme.font.main,
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  minHeight: 0,
  color: theme.color.typo.secondary,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  backgroundColor: theme.color.dropdown.bg.default,
  '&:hover': {
    backgroundColor: theme.color.dropdown.list.bg.hover,
  },
  '&:focus': {
    backgroundColor: theme.color.dropdown.list.bg.focus,
  },
}));
