import {
  SortButtonContainer,
  StyledSortButtonDown,
  StyledSortButtonUp,
} from './SortButton.styled';

interface ISortButtonProps {
  isSorted?: boolean;
  desc?: boolean;
}

export const SortButton = ({ isSorted, desc }: ISortButtonProps) => {
  const upDisabled = !(isSorted && !desc);
  const downDisabled = !(isSorted && desc);

  return (
    <SortButtonContainer>
      <StyledSortButtonUp
        data-testid="KeyboardArrowDownIcon"
        disabled={upDisabled}
        iconColor="secondary"
        iconName="ArrowTopIcon"
        iconSize={16}
      />
      <StyledSortButtonDown
        data-testid="KeyboardArrowUpIcon"
        disabled={downDisabled}
        iconColor="secondary"
        iconName="ArrowDownIcon"
        iconSize={16}
      />
    </SortButtonContainer>
  );
};
