import 'devextreme/dist/css/dx.light.css';
import {
  HtmlEditor as HtmlEditorDevExtreme,
  Toolbar,
  Item,
} from 'devextreme-react/html-editor';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { HtmlContainerStyled, HtmlErrorStyled } from './HtmlEditor.styled';
import { HtmlEditorProps } from './HtmlEditor.types';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Georgia', 'Tahoma', 'Times New Roman', 'Verdana'];
const headerValues = [false, 1, 2, 3, 4, 5];
const SPECIAL_SYMBOLS_REGEX =
  /.*{%\s*extends \S*\s*%}\s*{%\s*block content\s*%}[\s$]*(.*)[\s$]*{% endblock %}.*/is;

export const HtmlEditor: FC<HtmlEditorProps> = ({
  name,
  error,
  disabled,
  onHeaderFooterRemoved,
}) => {
  const [inner, setInner] = useState('');
  const [isSymbolsToShow, setIsSymbolsToShow] = useState(true);
  const [header, setHeader] = useState('');
  const [footer, setFooter] = useState('');

  const formContext = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error: fieldError },
  } = useController({
    control: formContext.control,
    name,
  });

  formContext.register(name);

  const onChangeReactHookForm = (val: string) => {
    onChange(val);
  };

  const updateInnerContent = (content: string) => {
    const matchedContent = content.match(SPECIAL_SYMBOLS_REGEX);
    let newInnerContent = content;

    if (isSymbolsToShow) {
      if (!content.includes(header) && !content.includes(footer)) {
        newInnerContent = `${header}${content}${footer}`;
        onHeaderFooterRemoved('', '');
      }
    } else if (matchedContent === null) {
      return;
    } else {
      newInnerContent = matchedContent[1];
      const [headerIn, footerIn] = content.split(matchedContent[1]);
      setHeader(headerIn);
      setFooter(footerIn);
      onHeaderFooterRemoved(headerIn, footerIn);
    }

    onChangeReactHookForm(newInnerContent);
    setInner(newInnerContent);
  };

  const errorText = error?.error || fieldError?.message;

  const handleShowMarkUp = useCallback(() => {
    if (isSymbolsToShow) {
      setIsSymbolsToShow(false);
    } else {
      setIsSymbolsToShow(true);
    }
  }, [isSymbolsToShow]);

  const toolbarButtonOptions = useMemo(
    () => ({
      text: `${isSymbolsToShow ? 'Hide' : 'Show'} markup`,
      stylingMode: 'text',
      onClick: handleShowMarkUp,
    }),
    [isSymbolsToShow],
  );

  useEffect(() => {
    updateInnerContent(value);
  }, [isSymbolsToShow]);

  return (
    <HtmlContainerStyled withError={!!errorText}>
      <HtmlEditorDevExtreme
        defaultValue={inner}
        valueType="html"
        value={value}
        onValueChange={onChange}
        allowSoftLineBreak
        placeholder="Template"
        disabled={disabled}
        height={800}
      >
        <Toolbar multiline>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item name="size" acceptedValues={sizeValues} />
          <Item name="font" acceptedValues={fontValues} />
          <Item name="separator" />
          <Item name="bold" />
          <Item name="italic" />
          <Item name="strike" />
          <Item name="underline" />
          <Item name="separator" />
          <Item name="alignLeft" />
          <Item name="alignCenter" />
          <Item name="alignRight" />
          <Item name="alignJustify" />
          <Item name="separator" />
          <Item name="orderedList" />
          <Item name="bulletList" />
          <Item name="separator" />
          <Item name="header" acceptedValues={headerValues} />
          <Item name="separator" />
          <Item name="color" />
          <Item name="background" />
          <Item name="separator" />
          <Item name="link" />
          <Item name="image" />
          <Item name="separator" />
          <Item name="clear" />
          <Item name="codeBlock" />
          <Item name="blockquote" />
          <Item name="separator" />
          <Item name="insertTable" />
          <Item name="deleteTable" />
          <Item name="insertRowAbove" />
          <Item name="insertRowBelow" />
          <Item name="deleteRow" />
          <Item name="insertColumnLeft" />
          <Item name="insertColumnRight" />
          <Item name="deleteColumn" />
          <Item name="cellProperties" />
          <Item name="tableProperties" />
          <Item widget="dxButton" options={toolbarButtonOptions} />
        </Toolbar>
      </HtmlEditorDevExtreme>

      {errorText && <HtmlErrorStyled>{errorText}</HtmlErrorStyled>}
    </HtmlContainerStyled>
  );
};
