import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../helpers/blockNonNativeProps';

import { TFormLayoutProps } from './FormLayout.types';

export const StyledFormLayout = styled('div', {
  shouldForwardProp: blockNonNativeProps('columns'),
})<TFormLayoutProps>(({ theme, columns }) => ({
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: '1fr',

  [theme?.breakpoints.up('md')]: {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  },
})) as React.ComponentType<TFormLayoutProps>;
