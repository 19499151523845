import { styled } from '@mui/material/styles';

import { IDescriptionProps, IMessageProps, ITitleProps } from './types';

export const Message = styled('div')(({ theme }: IMessageProps) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme?.color?.typo?.inverse,

  '& a': {
    color: theme?.color?.typo?.inverse,
    textDecoration: 'underline',
  },
}));

export const Title = styled('div')(({ theme }: ITitleProps) => ({
  fontSize: theme?.size?.text?.lg,
  lineHeight: '24px',
}));

export const Description = styled('div')(({ theme }: IDescriptionProps) => ({
  fontSize: theme?.size?.text?.md,
}));
