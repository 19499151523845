const NOTIFICATIONS_SHAPER_BASE = '/ns';

export const TEMPLATES_PATH = `${NOTIFICATIONS_SHAPER_BASE}/templates`;
export const TEMPLATES_ARCHIVE_PATH = `${TEMPLATES_PATH}/archive`;

export const ALL_TRANSLATIONS_PATH = `${NOTIFICATIONS_SHAPER_BASE}/translations`;

export const MAILINGS_PATH = `${NOTIFICATIONS_SHAPER_BASE}/mailings`;
export const MAILINGS_EDIT_PATH = `${MAILINGS_PATH}/:id`;
export const MAILINGS_CREATE_PATH = `${MAILINGS_PATH}/create-mailings`;

export const EVENTS_PATH = `${NOTIFICATIONS_SHAPER_BASE}/events`;
export const EVENT_EDIT_PATH = `${EVENTS_PATH}/:id`;
export const EVENT_CREATE_PATH = `${EVENTS_PATH}/create-event`;
