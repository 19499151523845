import { array, object, string } from 'yup';

const isRecipientsValid = array().of(
  string().email().required('This is a required field'),
);

export const MAILING_FORM_VALIDATION_SCHEMA = object({
  name: string().required(),
  brandings: array(),
  recipients_to: isRecipientsValid,
  recipients_cc: isRecipientsValid,
  recipients_bcc: isRecipientsValid,
});
