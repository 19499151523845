import {
  Mailing,
  MailingFormType,
} from '../../services/shaper/mailings/mailings.types';

export function getMailingForm(formData?: Mailing | null): MailingFormType {
  return {
    name: formData?.name || '',
    brandings: formData?.brandings || [],
    recipients_bcc: formData?.recipients_bcc || [],
    recipients_cc: formData?.recipients_cc || [],
    recipients_to: formData?.recipients_to || [],
  };
}

// helper for array fields (see https://github.com/react-hook-form/react-hook-form/discussions/7449)
export function checkIsDirty(
  fields: Record<string, boolean | boolean[]>,
): boolean {
  return !Object.values(fields).find((value) => {
    return Array.isArray(value) ? value.some((el: boolean) => el) : value;
  });
}
