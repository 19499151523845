import { Locales } from '../../../constants/common';
import { TemplateInfo } from '../../../services/shaper/templates/templates.types';
import { Flags } from '../../TranslationInfo/TranslationInfo.helpers';

const emptyTranslation = {
  percents: 0,
  percents_color: '#384345', // todo move to theme color
  missed_ids: [],
  new_ids: [],
};

export const defaultTranslations: TemplateInfo['translations_ratio'] = (
  Object.keys(Flags) as Locales[]
).map((locale) => ({
  locale,
  ...emptyTranslation,
}));
