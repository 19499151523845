import { IColumn } from 'react-ui-kit-exante';

import { TemplatesState } from '../../services/shaper/templates/templates.types';
import { formatDateWithTime } from '../../utils/date';

export const columns: IColumn<TemplatesState>[] = [
  {
    Header: 'Template',
    accessor: 'name',
    disableFilters: true,
    minWidth: 80,
  },
  {
    Header: 'Date/time',
    accessor: 'modified',
    Cell: ({ value }: { value: string }) => formatDateWithTime(value),
    disableFilters: true,
  },
];
