import { useController, useFormContext } from 'react-hook-form';
import { ISelectOption, Select } from 'react-ui-kit-exante';

interface IFormSelectContainerProps {
  fullWidth?: boolean;
  label?: string;
  name: string;
  options: Array<ISelectOption>;
  disabled?: boolean;
  readOnly?: boolean;
}

export const FormSelectContainer = ({
  fullWidth = true,
  label = '',
  disabled,
  name,
  options,
  readOnly,
}: IFormSelectContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const selectValue = !options.length || value === null ? '' : value;

  return (
    <Select
      error={!!error}
      fullWidth={fullWidth}
      label={label}
      message={error?.message}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      value={selectValue}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};
