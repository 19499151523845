import { object, string, array, boolean } from 'yup';

export const EVENT_FORM_VALIDATION_SCHEMA = object({
  event: object({
    name: string().required(),
    description: string().notRequired(),
    group: string().required(),
    tags: array().notRequired(),
    script: string().notRequired(),
  }),
  template: object({
    branding: string().notRequired(),
    name: string().notRequired(),
    event_script: string().notRequired(),
    tags: array().notRequired(),
    file_name: array().notRequired(),
    base: boolean().nullable(),
    content: string().required(),
    is_muted: boolean(),
    is_draft: boolean(),
    is_sms_as_fallback: boolean(),
    subject: string().required(),
    mailing_lists: array().notRequired(),
  }).nullable(),
});
