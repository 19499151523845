import { styled } from 'react-ui-kit-exante';

export const StyledPreviewContainer = styled('div')`
  position: relative;
`;

export const StyledErrorContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 12px;
`;

export const StyledPreviewLoader = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  opacity: 0.7,
  padding: 24,
  backgroundColor: theme?.color?.bg?.primary,
}));
