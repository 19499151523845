import { css, styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../../helpers/blockNonNativeProps';

interface IRootProps {
  isExpanded: boolean;
}

export const Root = styled('div', {
  shouldForwardProp: blockNonNativeProps('isExpanded'),
})<IRootProps>`
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1.5px solid currentColor;
  display: inline-grid;
  place-content: center;
  margin-right: 10px;

  ${({ isExpanded, theme }) => {
    if (isExpanded) {
      return css`
        color: 'currentColor';
        transform: rotate(90deg);

        padding-left: 1px;
      `;
    }
    return css`
      color: ${theme.color.typo.secondary};
      padding-left: 1px;
    `;
  }}
`;
