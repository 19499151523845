import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Delivered = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
          <path
            d="M19.345 12.415l-9.328 3.14a.394.394 0 00-.269.374.428.428 0 00.264.392l4.542 1.862a.427.427 0 01.21.185l2.414 4.274a.428.428 0 00.422.213.398.398 0 00.337-.314l1.952-9.646a.425.425 0 00-.544-.48z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
          <path
            d="M19.345 12.415l-9.328 3.14a.394.394 0 00-.269.374.428.428 0 00.264.392l4.542 1.862a.427.427 0 01.21.185l2.414 4.274a.428.428 0 00.422.213.398.398 0 00.337-.314l1.952-9.646a.425.425 0 00-.544-.48z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
          <path
            d="M19.345 12.415l-9.328 3.14a.394.394 0 00-.269.374.428.428 0 00.264.392l4.542 1.862a.427.427 0 01.21.185l2.414 4.274a.428.428 0 00.422.213.398.398 0 00.337-.314l1.952-9.646a.425.425 0 00-.544-.48z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
              strokeMiterlimit={10}
            />
            <path
              d="M19.345 12.415l-9.328 3.14a.394.394 0 00-.269.374.428.428 0 00.264.392l4.542 1.862a.427.427 0 01.21.185l2.414 4.274a.428.428 0 00.422.213.398.398 0 00.337-.314l1.952-9.646a.425.425 0 00-.544-.48z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Delivered;
