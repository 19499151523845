import * as cookies from 'js-cookie';

export const SESSION_ID_COOKIE_KEY = 'run-ui_notifications_session_id';
export const ACCESS_TOKEN_COOKIE_KEY = 'run-ui_access_token';

export function getSessionIdFromCookie(sessionKey?: string) {
  return cookies.get(sessionKey || SESSION_ID_COOKIE_KEY);
}

export function getAccessTokenFromCookie(accessTokenKey?: string) {
  const token = cookies.get(ACCESS_TOKEN_COOKIE_KEY || accessTokenKey);
  const parsedToken = token && JSON.parse(token);

  return parsedToken?.access_token;
}
