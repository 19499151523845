import { FC } from 'react';
import { Loader } from 'react-ui-kit-exante';

import {
  StyledErrorContainer,
  StyledPreviewContainer,
  StyledPreviewLoader,
} from './TemplatePreview.styled';
import { TemplatePreviewProps } from './TemplatePreview.types';

export const TemplatePreview: FC<TemplatePreviewProps> = ({
  isLoading,
  info,
}) => {
  return (
    <StyledPreviewContainer className="PreviewContainer">
      {isLoading && (
        <StyledPreviewLoader className="PreviewLoader">
          <Loader isCentered isInner />
        </StyledPreviewLoader>
      )}
      {info?.content && (
        <div
          className="PreviewWrapper"
          dangerouslySetInnerHTML={{ __html: info.content }}
        />
      )}
      <StyledErrorContainer className="ErrorContainer">
        {info?.error && <p>{info.error}</p>}
        {info?.lineno && <p>Line: {info.lineno}</p>}
      </StyledErrorContainer>
    </StyledPreviewContainer>
  );
};
