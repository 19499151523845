import { NavLink } from 'react-router-dom';
import { styled } from 'react-ui-kit-exante';

export const StyledHeaderList = styled('ul')`
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 18px;
  padding: 0;
  margin: 0;
`;

export const HeaderStyled = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  backgroundColor: theme?.palette?.success?.main,
  position: 'relative',
  padding: '0 24px',
  boxSizing: 'border-box',
}));

export const HeaderTitleStyled = styled('span')(({ theme }) => ({
  fontSize: '22px',
  color: theme?.color?.typo?.inverse,
}));

export const HeaderLinkStyled = styled(NavLink)(({ theme }) => ({
  display: 'inline-block',
  textDecoration: 'none',
  color: theme?.color?.typo?.inverse,
  padding: '16px',
  '&:hover': {
    backgroundColor: theme?.color?.bg?.promo,
  },
  '&.active': {
    backgroundColor: theme?.color?.typo?.inverse,
    color: theme?.color?.typo?.action,
    opacity: 0.9,
  },
}));
