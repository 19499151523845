import { FC } from 'react';

import {
  HeaderLinkStyled,
  HeaderStyled,
  HeaderTitleStyled,
  StyledHeaderList,
} from './Header.styled';
import { NAV_LINKS } from './constants';

export const Header: FC = () => {
  return (
    <HeaderStyled>
      <HeaderTitleStyled>Notification Shaper</HeaderTitleStyled>

      <StyledHeaderList>
        {NAV_LINKS.map(({ to, label }) => (
          <li key={to}>
            <HeaderLinkStyled end to={to}>
              {label}
            </HeaderLinkStyled>
          </li>
        ))}
      </StyledHeaderList>
    </HeaderStyled>
  );
};
