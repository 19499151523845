import { getDefaultReqParams, shaperApi } from '../consts';

import { FETCH_SETTINGS } from './settings.consts';
import { SettingsResponse } from './settings.types';

export function getSettingsReq() {
  return shaperApi.get<SettingsResponse>({
    endPoint: `${FETCH_SETTINGS}`,
    ...getDefaultReqParams(),
  });
}
