import { CancelToken } from 'axios';

import { apiService } from '../api/Api.service';
import {
  getAccessTokenFromCookie,
  getSessionIdFromCookie,
} from '../cookies/cookies.service';

const isStage = window.location.host.includes('stage');
const isLocalHost = window.location.host.includes('localhost');

const PROD_ENV = 'https://shaper.exante.eu';
const STAGE_ENV = 'https://shaper-stage.exante.eu';

export const SHAPER_ENDPOINT = isStage || isLocalHost ? STAGE_ENV : PROD_ENV;

export const getDefaultReqParams = (cancelToken?: CancelToken) => ({
  sessionToken: getSessionIdFromCookie() || '',
  accessToken: getAccessTokenFromCookie() || '',
  cancelToken,
});

export const shaperApi = apiService(
  window.NOTIFICATIONS_SHAPER_UI?.api_urls?.NOTIFICATIONS_SHAPER ||
    SHAPER_ENDPOINT,
);
