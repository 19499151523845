import { FC, useCallback, useMemo, useState } from 'react';
import {
  Notification,
  Table,
  useTableData,
  Modal,
  useModal,
  IconButton,
} from 'react-ui-kit-exante';

import { DEFAULT_TABLE_PAGE_SIZE, Notifications } from '../../constants/common';
import { TemplatesService } from '../../services/shaper/templates';
import { unarchiveTemplateReq } from '../../services/shaper/templates/templates.requests';
import {
  TemplatesState,
  Template,
} from '../../services/shaper/templates/templates.types';
import { calculateCountOfPages } from '../../utils';

import { columns } from './columns';

export const TemplatesArchive: FC = () => {
  const restoreModal = useModal();
  const [restoreData, setRestoreData] = useState<Template | null>(null);
  const [isRestoring, setIsRestoring] = useState(false);

  const getTemplates = useCallback(
    ({ params }: any) => TemplatesService.getArchiveTemplates(params),
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: {
        onFetch: getTemplates,
        onFailure: (e: Error) => Notification.error({ title: e.message }),
      },
    }),
    [getTemplates],
  );

  const {
    data,
    isLoading,
    setSorting,
    setPage,
    page,
    limit,
    setLimit,
    fetchData,
  } = useTableData<TemplatesState>(tableDataArgs);

  const total = data?.total || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const restoreClickHandler = (row: Template) => {
    restoreModal.onOpen();
    setRestoreData(row);
  };

  const onCloseRestoreModalHandler = useCallback(() => {
    restoreModal.onClose();
    setRestoreData(null);
    setIsRestoring(false);
  }, []);

  const onRestoreConfirmHandler = useCallback(async () => {
    const successMessage = `${Notifications.TemplateType} is ${Notifications.SuccessUnarchive}`;
    if (restoreData) {
      setIsRestoring(true);
      try {
        await unarchiveTemplateReq(restoreData.name);
        Notification.success({ title: successMessage });
      } catch (error: any) {
        Notification.error(error?.message);
      } finally {
        onCloseRestoreModalHandler();
        await fetchData();
      }
    }
  }, [restoreData]);

  return (
    <>
      <Table
        title="Archive"
        columns={columns}
        data={data?.result || []}
        isLoading={isLoading}
        showTableInfo
        tableId="notification-shaper-templates-archive"
        hasPagination
        manualSortBy
        defaultSortBy={[]}
        onSort={setSorting}
        isFlexLayout
        rowActions={{
          show: true,
          hideEdit: true,
          additionalActions: [
            {
              label: (
                <IconButton
                  iconName="RollbackIcon"
                  iconSize={24}
                  iconColor="action"
                />
              ),
              onClick: restoreClickHandler,
              title: 'Restore template',
              width: 100,
            },
          ],
        }}
        serverPaginationProps={{
          pageSize: limit,
          setPage,
          setPageSize: setLimit,
          pageIndex: page,
          total,
          pageCount,
        }}
        pageSize={DEFAULT_TABLE_PAGE_SIZE}
        className="TemplatesArchiveTable"
      />
      <Modal
        isOpened={restoreModal.isOpened}
        onClose={onCloseRestoreModalHandler}
        title="Are you sure?"
        confirmButton={{
          confirmButtonIsDisabled: isRestoring,
          handleConfirm: onRestoreConfirmHandler,
        }}
        isLoading={isRestoring}
      >
        <p>
          Are you sure you want to restore the selected template (
          <b>{restoreData?.name}</b>)?
        </p>
      </Modal>
    </>
  );
};
