import axios, { AxiosInstance, AxiosPromise, CancelToken, Method } from 'axios';

import { paramsSerializer } from './Api.helpers';

axios.defaults.withCredentials = false;
axios.defaults.timeout = 150000;

type TOptions = {
  contentType?: string;
  responseType?:
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'json'
    | 'text'
    | 'stream';
  cancelToken?: CancelToken;
  data?: any;
  method?: Method;
  endPoint?: string;
  url?: string;
  headers?: any;
  accessToken?: string;
  sessionToken?: string;
  params?: {
    [key: string]: any; // paramsSerializer can parse this types
  };
};

export enum Headers {
  X_AUTH_SESSIONID = 'x-auth-sessionid',
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'authorization',
}

enum Methods {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export const apiService = (baseURL: string, service: AxiosInstance = axios) => {
  const getHeaders = (
    contentType: string,
    sessionToken?: string,
    accessToken?: string,
  ) => {
    const headers: any = {
      [Headers.CONTENT_TYPE]: contentType,
    };

    if (sessionToken) {
      headers[Headers.X_AUTH_SESSIONID] = sessionToken;
    }

    if (!sessionToken && accessToken) {
      headers[Headers.AUTHORIZATION] = `Bearer ${accessToken}`;
    }

    return headers;
  };

  const request: <T>(options: TOptions) => AxiosPromise<T> = ({
    contentType = 'application/json',
    responseType = 'json',
    endPoint = '',
    headers,
    method,
    sessionToken,
    accessToken,
    url = baseURL,
    params,
    ...options
  }) =>
    service({
      url: `${url}${endPoint}`,
      method,
      headers: {
        ...getHeaders(contentType, sessionToken, accessToken),
        ...headers,
      },
      params,
      paramsSerializer,
      responseType,
      ...options,
    });

  const get: <T>(options: TOptions) => AxiosPromise<T> = ({
    data,
    ...options
  }) =>
    request({
      ...options,
      method: Methods.GET,
    });

  const head: <T>(options: TOptions) => AxiosPromise<T> = ({
    data,
    ...options
  }) =>
    request({
      ...options,
      method: Methods.HEAD,
    });

  const post = <T>(options: TOptions) =>
    request<T>({ method: Methods.POST, ...options });
  const put = <T>(options: TOptions) =>
    request<T>({ method: Methods.PUT, ...options });
  const patch = <T>(options: TOptions) =>
    request<T>({ method: Methods.PATCH, ...options });

  return {
    delete: <T>(options: TOptions) =>
      request<T>({ method: Methods.DELETE, ...options }),
    get,
    post,
    put,
    patch,
    head,
  };
};
