import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { IconButton } from 'react-ui-kit-exante';

import { FormInputContainer } from '../FormInputContainer/FormInputContainer';

import {
  StyledFormArrayFieldContainer,
  StyledFormArrayFieldsWrapper,
} from './FormArrayFields.styled';
import { FormArrayFieldsProps } from './FormFieldsArray.types';

export const FormArrayFields: FC<FormArrayFieldsProps> = ({
  control,
  name,
  label,
  disabled,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <StyledFormArrayFieldsWrapper>
      {fields.map((item, index) => {
        return (
          <StyledFormArrayFieldContainer key={item.id}>
            <FormInputContainer
              name={`${name}.${index}`}
              type="text"
              label={label}
              disabled={disabled}
            />
            <IconButton
              iconName="CloseIcon"
              onClick={() => remove(index)}
              disabled={disabled}
            />
          </StyledFormArrayFieldContainer>
        );
      })}
      <IconButton
        onClick={() => append('')}
        disabled={disabled}
        iconName="AddIcon"
        iconColor="action"
        label="Add field"
      />
    </StyledFormArrayFieldsWrapper>
  );
};
