import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Notification,
  Table,
  useTableData,
  Modal,
  useModal,
  IconButton,
} from 'react-ui-kit-exante';

import { DEFAULT_TABLE_PAGE_SIZE, Notifications } from '../../constants/common';
import { MAILINGS_CREATE_PATH } from '../../routes';
import { MailingsService } from '../../services/shaper/mailings';
import { deleteMailingListReq } from '../../services/shaper/mailings/mailings.requests';
import {
  Mailing,
  MailingListsState,
} from '../../services/shaper/mailings/mailings.types';
import { calculateCountOfPages } from '../../utils';

import { columns, hiddenColumns } from './columns';

export const MailingLists: FC = () => {
  const navigate = useNavigate();
  const deleteModal = useModal();
  const [deletingData, setDeletingData] = useState<Mailing | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const getMailings = useCallback(
    ({ params }: any) => MailingsService.getMailingLists(params),
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: {
        onFetch: getMailings,
        onFailure: (e: Error) => Notification.error({ title: e.message }),
      },
    }),
    [getMailings],
  );

  const {
    data,
    isLoading,
    setSorting,
    setPage,
    page,
    limit,
    setLimit,
    fetchData,
  } = useTableData<MailingListsState>(tableDataArgs);

  const total = data?.total || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const deleteClickHandler = (row: Mailing) => {
    deleteModal.onOpen();
    setDeletingData(row);
  };

  const onCloseDeleteModalHandler = useCallback(() => {
    deleteModal.onClose();
    setDeletingData(null);
    setIsDeleting(false);
  }, []);

  const onDeleteConfirmHandler = useCallback(async () => {
    const successMessage = `${Notifications.MailingType} is ${Notifications.SuccessDelete}`;

    if (deletingData) {
      setIsDeleting(true);
      try {
        await deleteMailingListReq(String(deletingData.id));
        Notification.success({ title: successMessage });
      } catch (error: any) {
        Notification.error(error?.message);
      } finally {
        onCloseDeleteModalHandler();
        await fetchData();
      }
    }
  }, [deletingData]);

  const displayedColumnKeys = columns
    .filter((column) => !hiddenColumns.includes(column.Header as string))
    .map((column) => column.accessor) as string[];

  const onAdd = () => {
    navigate(MAILINGS_CREATE_PATH);
  };

  return (
    <>
      <Table
        title="Mailing Lists"
        onAdd={onAdd}
        columns={columns}
        data={data?.result || []}
        isLoading={isLoading}
        showTableInfo
        tableId="notification-shaper-mailing-lists"
        displayedColumnKeys={displayedColumnKeys}
        hasPagination
        manualSortBy
        defaultSortBy={[]}
        onSort={setSorting}
        isFlexLayout
        rowActions={{
          show: true,
          additionalActions: [
            {
              label: (
                <IconButton
                  iconName="DeleteIcon"
                  iconSize={24}
                  iconColor="secondary"
                />
              ),
              onClick: deleteClickHandler,
              title: 'Archive',
              width: 100,
            },
          ],
        }}
        serverPaginationProps={{
          pageSize: limit,
          setPage,
          setPageSize: setLimit,
          pageIndex: page,
          total,
          pageCount,
        }}
        pageSize={DEFAULT_TABLE_PAGE_SIZE}
      />
      <Modal
        isOpened={deleteModal.isOpened}
        onClose={onCloseDeleteModalHandler}
        title="Are you sure?"
        confirmButton={{
          confirmButtonIsDisabled: isDeleting,
          handleConfirm: onDeleteConfirmHandler,
        }}
        isLoading={isDeleting}
      >
        <div>
          Are you sure you want to delete the selected mailing list (
          <b>{deletingData?.name}</b>)?
          <br />
          All of the following objects and their related items will be deleted.
        </div>
      </Modal>
    </>
  );
};
