import classNames from 'classnames';
import { FC } from 'react';

import { DefaultThemeProvider } from '../../theme';

import { DEFAULT_COLUMNS_COUNT } from './FormLayout.constants';
import { StyledFormLayout } from './FormLayout.styled';
import { TFormLayoutProps } from './FormLayout.types';

export const FormLayout: FC<TFormLayoutProps> = ({
  children,
  className = '',
  columns = DEFAULT_COLUMNS_COUNT,
}) => {
  return (
    <DefaultThemeProvider>
      <StyledFormLayout
        className={classNames(['FormLayout', className])}
        columns={columns}
        data-test-id="rui__form-layout__wrapper"
      >
        {children}
      </StyledFormLayout>
    </DefaultThemeProvider>
  );
};
