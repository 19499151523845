import { FC } from 'react';
import { FormLayout, InfoIcon, Tooltip } from 'react-ui-kit-exante';

import { FormCheckboxContainer } from 'components/Form/FormCheckboxContainer';
import { ChannelType, TooltipTexts } from 'constants/common';

import {
  StyledTemplateCheckboxLabel,
  StyledTemplateCheckboxes,
} from './TemplateCheckboxes.styled';
import { TTemplateCheckboxesProps } from './TemplateCheckboxes.types';

export const TemplateCheckboxes: FC<TTemplateCheckboxesProps> = ({
  type,
  isEditable,
}) => {
  return (
    <FormLayout>
      <StyledTemplateCheckboxes className="TemplateCheckboxes">
        <FormCheckboxContainer
          label={
            <StyledTemplateCheckboxLabel>
              Is muted{' '}
              <Tooltip title={TooltipTexts.IS_MUTED}>
                <InfoIcon />
              </Tooltip>
            </StyledTemplateCheckboxLabel>
          }
          name="template.is_muted"
          disabled={!isEditable}
        />
        <FormCheckboxContainer
          label={
            <StyledTemplateCheckboxLabel>Draft</StyledTemplateCheckboxLabel>
          }
          name="template.is_draft"
          disabled={!isEditable}
        />
        {type === ChannelType.Email && (
          <FormCheckboxContainer
            label="Base template"
            name="template.base"
            noWrap
            disabled={!isEditable}
          />
        )}
        {type === ChannelType.Phone && (
          <FormCheckboxContainer
            label={
              <StyledTemplateCheckboxLabel>
                SMS as fallback{' '}
                <Tooltip title={TooltipTexts.IS_SMS_AS_FALLBACK}>
                  <InfoIcon />
                </Tooltip>
              </StyledTemplateCheckboxLabel>
            }
            name="template.is_sms_as_fallback"
            disabled={!isEditable}
          />
        )}
      </StyledTemplateCheckboxes>
    </FormLayout>
  );
};
