import { ClickAwayListener } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';

import {
  StyledArrowIcon,
  StyledArrowIconWrapper,
  StyledMonthName,
} from '../CalendarHeader.styled';

import {
  StyledCalendarMenuItem,
  StyledCalendarMenuList,
  StyledCalendarSelectPaper,
  StyledCalendarSelectPopper,
} from './CalendarSelect.styled';
import { ICalendarSelectProps } from './types';

export const CalendarSelect: FC<ICalendarSelectProps> = ({
  items,
  title,
  current,
  disabled,
  onItemClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (value: number) => {
    setAnchorEl(null);
    onItemClick(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentItem = items.find(([key]) => key === current);

  return (
    <>
      <StyledMonthName onClick={handleClick} disabled={disabled}>
        {title || currentItem?.[1]}
        {!disabled && (
          <StyledArrowIconWrapper>
            <StyledArrowIcon size="sm" direction="down" />
          </StyledArrowIconWrapper>
        )}
      </StyledMonthName>
      <StyledCalendarSelectPopper open={open} anchorEl={anchorEl} disablePortal>
        <StyledCalendarSelectPaper elevation={1}>
          <ClickAwayListener onClickAway={handleClose}>
            <StyledCalendarMenuList autoFocusItem={open}>
              {items.map(([key, itemTitle]) => {
                return (
                  <StyledCalendarMenuItem
                    key={key}
                    selected={key === currentItem?.[0]}
                    onClick={() => handleItemClick(key)}
                    disableRipple
                  >
                    {itemTitle}
                  </StyledCalendarMenuItem>
                );
              })}
            </StyledCalendarMenuList>
          </ClickAwayListener>
        </StyledCalendarSelectPaper>
      </StyledCalendarSelectPopper>
    </>
  );
};
