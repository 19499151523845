import { capitalize } from 'lodash';
import { ISelectOption } from 'react-ui-kit-exante';

export function transformVariantsToSelectOptions(
  variants?: string[],
  capitalized = false,
): ISelectOption[] {
  if (!variants) {
    return [];
  }
  return variants.map((type) => ({
    label: capitalized ? capitalize(type) : type,
    value: type,
  }));
}
