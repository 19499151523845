import { ComponentType } from 'react';
import { Theme, styled } from 'react-ui-kit-exante';

type HtmlContainerProps = {
  theme?: Theme;
  withError?: boolean;
};
export const HtmlContainerStyled = styled('div')(
  ({ theme, withError }: HtmlContainerProps) => ({
    '.dx-quill-container': {
      minHeight: '150px',
    },
    '.dx-htmleditor': {
      border: withError ? `1px solid ${theme?.color?.input?.error}` : 'initial',
    },
  }),
) as ComponentType<React.PropsWithChildren<HtmlContainerProps>>;

export const HtmlErrorStyled = styled('div')(({ theme }) => ({
  color: theme?.color?.input?.error,
  padding: '10px 0',
}));
