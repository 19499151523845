import { ComponentType } from 'react';
import { styled, FormLayout } from 'react-ui-kit-exante';

import { StyledTabProps } from './Email.types';

export const StyledEmailTemplateWrapper = styled('div')`
  background-color: ${({ theme }) => theme.color.bg.primary};

  margin-bottom: 24px;
  border: 1px solid ${({ theme }) => theme.color.line.primary};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledEmailEditorWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    border-right: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;

export const StyledTabContainer = styled('div')`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledTab = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive, theme }: StyledTabProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '32px',
  padding: '7px 0',
  width: '100%',
  backgroundColor: isActive
    ? theme?.color?.bg?.secondary
    : theme?.color?.bg?.primary,
  color: isActive ? theme?.color?.typo?.promo : theme?.color?.typo?.action,
  border: `1px solid ${theme?.color?.line?.primary}`,
  cursor: isActive ? 'initial' : 'pointer',
  '&:not(:first-of-type)': {
    borderLeft: 'none',
  },
})) as ComponentType<React.PropsWithChildren<StyledTabProps>>;

export const StyledContentContainer = styled('div')`
  max-height: 800px;
  overflow-y: auto;
  height: 100%;
`;

export const StyledSendEmailForm = styled(FormLayout)`
  margin-top: 24px;
`;

export const StyledEmailTemplateSettings = styled(FormLayout)`
  margin-bottom: 24px;
`;
