import { yupResolver } from '@hookform/resolvers/yup';
import { ReactElement, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  IconButton,
  Modal,
  NoData,
  Notification,
  useModal,
} from 'react-ui-kit-exante';

import { Notifications, PAGES } from 'constants/common';
import { PageContext } from 'contexts/PagesContext';
import { EVENTS_PATH, TEMPLATES_PATH } from 'routes';

import { EventsService } from '../../services/shaper/events';
import {
  TEventDetail,
  TEventForm,
} from '../../services/shaper/events/events.types';
import { TemplatesService } from '../../services/shaper/templates';
import { FormInputContainer } from '../Form/FormInputContainer/FormInputContainer';

import { EVENT_FORM_VALIDATION_SCHEMA } from './EventForm.consts';
import { getEventForm } from './EventForm.helpers';
import {
  StyledEditEventPanel,
  StyledEventFormActions,
} from './EventForm.styled';
import { LeaveModalContextProvider } from './TemplateForm/contexts/ConfirmLeaveContext';
import { TemplateTabsProvider } from './TemplatesTabs/TemplateTabsContext';
import { TemplatesTabs } from './TemplatesTabs/TemplatesTabs';

export type EventFormProps = {
  eventData?: TEventDetail | null;
  eventDataFetch?: () => Promise<void>;
  id?: string;
};

export const EventForm = ({
  eventData,
  id,
  eventDataFetch,
}: EventFormProps) => {
  const navigate = useNavigate();
  const { pageFrom, filterParams } = useContext(PageContext);
  const [, setSearchParams] = useSearchParams();
  const commentModal = useModal();
  const [isSaving, setIsSaving] = useState(false);

  const onCloseHandler = () => {
    if (pageFrom === PAGES.TEMPLATES) {
      navigate(TEMPLATES_PATH + filterParams);
    } else {
      navigate(EVENTS_PATH + filterParams);
    }
  };

  const defaultValues = getEventForm(eventData);

  const methods = useForm<TEventForm>({
    defaultValues,
    resolver: yupResolver(EVENT_FORM_VALIDATION_SCHEMA),
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isSubmitting, dirtyFields },
  } = methods;

  const onCloseModal = () => {
    commentModal.onClose();
    setValue('template.change_comment', '');
  };

  const saveFormData = async () => {
    setIsSaving(true);
    const { event, template } = getValues();

    let responseEvent;
    let responseTemplate;

    const responseEventSuccessMessage = `${Notifications.EventType} is ${Notifications.SuccessSave}`;
    const responseTemplateSuccessMessage = `${Notifications.TemplateType} is ${Notifications.SuccessSave}`;

    try {
      if (dirtyFields.event) {
        responseEvent = id
          ? await EventsService.updateEvent(id, event)
          : await EventsService.createEvent(event);
      }

      if (template && dirtyFields.template) {
        responseTemplate = template?.created
          ? await TemplatesService.updateTemplate(template.name, template)
          : await TemplatesService.createTemplate(template);
      }

      if (responseEvent) {
        Notification.success({ title: responseEventSuccessMessage });
      }

      if (responseTemplate) {
        Notification.success({ title: responseTemplateSuccessMessage });
      }

      const shouldCreateEventWithTemplate =
        responseEvent && !id && responseTemplate;
      const shouldCreateOnlyEvent = responseEvent && !id && !responseTemplate;
      const shouldUpdateEvent = responseEvent && id && eventDataFetch;
      const shouldSetTemplate = responseTemplate?.name;

      if (shouldCreateEventWithTemplate) {
        navigate(
          `${EVENTS_PATH}/${responseEvent?.id}?templateId=${responseTemplate?.name}`,
        );
      } else if (shouldCreateOnlyEvent) {
        navigate(`${EVENTS_PATH}/${responseEvent?.id}`);
      } else if (shouldUpdateEvent) {
        await eventDataFetch();
      }
      if (shouldSetTemplate) {
        const isNewTemplate = Boolean(!template?.id);
        setSearchParams({ templateId: String(responseTemplate?.name) });
        if (isNewTemplate) {
          if (eventDataFetch) {
            await eventDataFetch();
          }
        } else {
          const values = {
            ...getValues(),
            template: {
              ...getValues('template'),
              id: responseTemplate?.name ? String(responseTemplate.name) : '',
            },
          };
          reset(values);
        }
      }
    } catch (error: any) {
      Notification.error({
        title: 'Error',
        description: (
          <span
            dangerouslySetInnerHTML={{
              __html: error?.message,
            }}
          />
        ),
      });
    } finally {
      setIsSaving(false);
      onCloseModal();
    }
  };

  const onSave = () => {
    saveFormData();
  };

  const onSavePrevent = () => {
    const { template } = getValues();
    if (template && dirtyFields.template) {
      commentModal.onOpen();
    } else {
      onSave();
    }
  };

  const isNoData = id && !eventData;

  const isEventEditable: boolean = (!eventData && !id) || !!eventData?.edit;

  const isDisabled = !Object.keys(dirtyFields).length || isSubmitting;

  const confirmButtonIsDisabled = !watch('template.change_comment');

  const modalName = id ? 'Save' : 'Create';

  const eventFormActions: ReactElement = (
    <StyledEventFormActions className="EditEventFormActions">
      <IconButton
        data-test-id="event__button--save"
        type="button"
        iconName="SaveIcon"
        iconSize={32}
        iconColor="action"
        disabled={isDisabled}
        label={modalName}
        className="EditEventButtonSave"
        onClick={handleSubmit(onSavePrevent)}
      />
      <IconButton
        iconName="CloseIcon"
        iconSize={32}
        iconColor="secondary"
        onClick={onCloseHandler}
        data-test-id="event__button--close"
        className="EditEventButtonClose"
        title="Close event page"
      />
    </StyledEventFormActions>
  );

  return (
    <LeaveModalContextProvider>
      <TemplateTabsProvider>
        <FormProvider {...methods}>
          {isNoData ? (
            <NoData />
          ) : (
            <form className="EditEventForm">
              <StyledEditEventPanel
                title={eventData?.name || 'New Event'}
                action={eventFormActions}
                className="EditEventPanel"
              >
                <TemplatesTabs
                  channels={eventData?.channels}
                  eventDataFetch={eventDataFetch}
                  isEventEditable={isEventEditable}
                />

                {getValues('template') && (
                  <Modal
                    isOpened={commentModal.isOpened}
                    onClose={onCloseModal}
                    title={`${modalName} template`}
                    confirmButton={{
                      confirmButtonName: modalName,
                      confirmButtonIsDisabled,
                      handleConfirm: onSave,
                    }}
                    isLoading={isSaving}
                  >
                    <p>State the reason for the change</p>
                    <FormInputContainer
                      label="Comment"
                      name="template.change_comment"
                      type="text"
                    />
                  </Modal>
                )}
              </StyledEditEventPanel>
            </form>
          )}
        </FormProvider>
      </TemplateTabsProvider>
    </LeaveModalContextProvider>
  );
};
