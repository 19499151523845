import { Table, styled } from 'react-ui-kit-exante';

export const StyledTranslationsActions = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const StyledTranslationsTable = styled(Table)`
  margin: 16px -24px 0;
`;

export const StyledTranslationsTableHeader = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  & > svg {
    width: 18px;
    height: 18px;
  }
`;
