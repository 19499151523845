import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../../../../helpers';
import { ArrowIcon } from '../../../../../Icons/ArrowIcon';

import { IStyledMonthNameProps } from './types';

export const StyledCurrentMonth = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 0,
  marginBottom: '8px',
  fontWeight: 500,
  fontSize: theme?.size?.text?.lg,
  textTransform: 'capitalize',
  color: theme?.color?.datepicker?.colorDay,
}));

export const StyledMonthName = styled('span', {
  shouldForwardProp: blockNonNativeProps(['disabled']),
})<IStyledMonthNameProps>(({ disabled }) => ({
  cursor: !disabled && 'pointer',
  pointerEvents: disabled ? 'none' : 'auto',
  padding: '0 2px',
}));

export const StyledMonthSwitchButton = styled('button')(({ theme }) => ({
  display: 'inline-flex',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: '0',
  color: theme?.color?.datepicker?.colorDay,

  '&:disabled': {
    opacity: 0.4,
    pointerEvents: 'none',
  },
}));

export const StyledArrowIconWrapper = styled('span')({
  padding: '0 2px',
});

export const StyledArrowIcon = styled(ArrowIcon)({
  verticalAlign: 'middle',
});
