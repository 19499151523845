import { Theme } from '@mui/material/styles';

import { defaultIconColors } from './const';
import { TIconColor, TIconName } from './types';

export function getColor(
  theme: Theme,
  iconColor: TIconColor,
  iconName?: TIconName,
): string {
  return theme?.color.icon[
    iconColor || defaultIconColors[iconName] || 'primary'
  ];
}
