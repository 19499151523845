import { ChannelType } from '../../constants/common';

import { ActiveCell } from './Translations.types';

export const defaultActiveCell: ActiveCell = {
  rowIdx: null,
  colHeader: null,
  templateData: null,
};

export const translationsTab = [
  {
    type: ChannelType.Email,
  },
  {
    type: ChannelType.Phone,
  },
  {
    type: ChannelType.Internal,
  },
];

export enum TranslationsNotifications {
  Type = 'Translation',
  SuccessPush = 'Successfully pushed',
  SuccessPull = 'Successfully pulled',
}
