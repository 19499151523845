import { useCallback, useEffect, useMemo } from 'react';
import { UseColumnOrderInstanceProps } from 'react-table';

import { LocalStorage } from '../../../../helpers';
import { addInitialColumnKeysOnEditable } from '../../helpers';
import { IColumn, TableViewParamsSchema } from '../../types';

interface IUseDisplayedColumnsOrderProps<Data extends object>
  extends UseColumnOrderInstanceProps<Data> {
  storage: LocalStorage<TableViewParamsSchema>;
  saveColumnOrder?: boolean;
  columnOrder: string[];
  visibleColumns: IColumn<Data>[];
  columns: IColumn<Data>[];
}

export const useColumnsOrder = <Data extends object>({
  saveColumnOrder,
  setColumnOrder,
  storage,
  visibleColumns,
  columnOrder,
  columns,
}: IUseDisplayedColumnsOrderProps<Data>) => {
  const savedColumnOrder = useMemo(() => storage.get('columnOrder'), [storage]);

  const order =
    columnOrder.length !== 0
      ? columnOrder
      : visibleColumns.map((col) => col.id);

  const defaultColumnOrder = useMemo(() => {
    const defaultOrder = storage.get('defaultColumnOrder');
    if (!defaultOrder) {
      storage.set(
        'defaultColumnOrder',
        addInitialColumnKeysOnEditable(order, columns),
      );
      return order;
    }
    return defaultOrder;
  }, [order, storage]);

  const resetToDefaultColumnOrder = useCallback(() => {
    setColumnOrder(defaultColumnOrder);
    storage.set('columnOrder', defaultColumnOrder);
  }, []);

  useEffect(() => {
    if (savedColumnOrder && saveColumnOrder) {
      setColumnOrder(savedColumnOrder);
    }
  }, [saveColumnOrder, savedColumnOrder, setColumnOrder]);

  return {
    order,
    defaultColumnOrder,
    resetToDefaultColumnOrder,
  };
};
