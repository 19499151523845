import { CancelToken } from 'axios';

import { ChannelType } from '../../../constants/common';
import { ResponseWithStatus } from '../../api/Api.types';

import {
  getTranslationsByRevisionReq,
  pushKeysReq,
  pullNewReq,
} from './translations.requests';
import { TranslationFullInfo } from './translations.types';

export const getTranslationsByRevision = async (
  type: ChannelType,
  cancelToken: CancelToken,
): Promise<TranslationFullInfo> => {
  try {
    const params = {
      type,
    };
    const response = await getTranslationsByRevisionReq(params, cancelToken);
    return response?.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const pushKeys = async (): Promise<ResponseWithStatus> => {
  try {
    const response = await pushKeysReq();
    return response?.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const pullNew = async (): Promise<TranslationFullInfo> => {
  try {
    const response = await pullNewReq();
    return response?.data;
  } catch (error: any) {
    throw new Error(error);
  }
};
