import { styled } from '@mui/material/styles';
import { HTMLAttributes } from 'react';

import { IconButton } from '../../Button/IconButton';

export type SortButtonProps = HTMLAttributes<HTMLSpanElement>;

export const SortButtonContainer = styled('span')<SortButtonProps>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 2,
    height: '100%',
    justifyContent: 'center',

    '& .MuiIcon-colorAction': {
      color: theme.color.icon.primary,
    },
    '& .MuiIcon-colorDisabled': {
      color: theme.color.icon.secondary,
    },
  }),
);

export const StyledSortButtonUp = styled(IconButton)(() => ({
  transform: `translateY(3px)`,
}));

export const StyledSortButtonDown = styled(IconButton)(() => ({
  transform: `translateY(-3px)`,
}));
