import { ISelectOption } from '../../../Inputs/Select';

export const prepareCheckboxValue = (value: unknown): boolean => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  if (typeof value === 'boolean') {
    return value;
  }

  return false;
};

export function prepareSelectOptions(options: unknown[] | ISelectOption[]) {
  return options.map((option) => {
    if (typeof option === 'string') {
      return { value: option, label: option };
    }

    return option;
  });
}

export const isSelectOptions = (value: unknown): value is ISelectOption[] =>
  Array.isArray(value);

export const prepareValueForMultiSelect = (
  value: unknown,
  options: ISelectOption[],
): string[] | ISelectOption[] => {
  if (!value) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  }
  if (typeof value === 'string') {
    return value
      .split(',')
      .map((s) =>
        options.find(({ value: optionValue }) => optionValue === s.trim()),
      );
  }
  return [];
};
