import { IColumn } from 'react-ui-kit-exante';

import { Comment } from '../../../../services/shaper/templates/templates.types';
import { formatDateWithTime } from '../../../../utils/date';

export const columns: IColumn<Comment>[] = [
  {
    Header: 'Date/Time',
    accessor: 'date_created',
    disableFilters: true,
    Cell: ({ value }: { value: string }) => formatDateWithTime(value),
  },
  {
    Header: 'User',
    accessor: 'user',
    disableFilters: true,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    disableFilters: true,
  },
];
