import qs from 'qs';

export function getParameterValueByQueryName(
  name: string,
  url = window.location.href,
) {
  const query = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${query}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getArrFromParam = (value: string) => {
  const split = value.split(',');
  return split.length > 1 ? split : value;
};

export function getParamsFromUrl() {
  const queryParams = qs.parse(window.location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  });

  return Object.keys(queryParams).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: getArrFromParam(getParameterValueByQueryName(curr)),
    }),
    {},
  );
}
