import { FC, useCallback, useEffect } from 'react';
import { Notification, useData } from 'react-ui-kit-exante';

import { InfoField } from '../../../components/InfoField/InfoField';
import { TemplatePreview } from '../../../components/TemplatePreview/TemplatePreview';
import { TemplatesService } from '../../../services/shaper/templates';
import {
  DebugNotificationParams,
  DebugTemplateInfo,
} from '../../../services/shaper/templates/templates.types';

import {
  StyledTranslationPreviewHeader,
  StyledTranslationPreviewPanel,
} from './TranslationPreview.styled';
import { TranslationPreviewProps } from './TranslationPreview.types';

export const isPublishedMap = ['Not published', 'Published'];

export const TranslationPreview: FC<TranslationPreviewProps> = ({
  translationData,
  templateData,
}) => {
  const onDebug = useCallback(async () => {
    try {
      const params: DebugNotificationParams = {
        debug: true,
        locale: templateData.locale,
        event: templateData.event,
      };

      return await TemplatesService.debugNotification(params);
    } catch (error: any) {
      Notification.error(error?.message);
    }
    return null;
  }, [templateData]);

  const debugData = useData<DebugTemplateInfo | null>({
    onFetch: onDebug,
  });

  useEffect(() => {
    if (templateData) {
      debugData.fetchData();
    }
  }, [templateData]);

  return (
    <StyledTranslationPreviewPanel className="TranslationPreviewPanel">
      <StyledTranslationPreviewHeader className="TranslationPreviewHeader">
        <InfoField title="Uuid" data={translationData.uuid} />
        <InfoField
          title="Published"
          data={isPublishedMap[Number(translationData.published)]}
        />
      </StyledTranslationPreviewHeader>
      <TemplatePreview isLoading={debugData.isLoading} info={debugData.data} />
    </StyledTranslationPreviewPanel>
  );
};
