import { FC, useCallback, useEffect, useRef } from 'react';
import { Notification, useData, Loader } from 'react-ui-kit-exante';

import { TypesVerbose } from 'contexts/SettingsContext';
import { TemplateInfo, TemplatesService } from 'services/shaper/templates';

import { TemplateForm } from '../TemplateForm/TemplateForm';
import { TemplateHistory } from '../TemplateForm/TemplateHistory';

import { StyledTemplateFormWrapper } from './Template.styled';

type TemplateProps = {
  id?: string | null;
  type: TypesVerbose['name'];
  brandingsUsed: string[];
  templateHistoryState: boolean;
  onCloseTemplateHistory: VoidFunction;
};

export const Template: FC<TemplateProps> = ({
  id,
  type,
  brandingsUsed,
  templateHistoryState,
  onCloseTemplateHistory,
}) => {
  const historyContainerRef = useRef<HTMLDivElement>(null);

  const getTemplate = useCallback(async () => {
    if (id) {
      try {
        return await TemplatesService.getTemplate(id);
      } catch (error: any) {
        Notification.error(error?.message);
      }
    }
    return null;
  }, [id]);

  const { fetchData, isLoading, data } = useData<TemplateInfo | null>({
    onFetch: getTemplate,
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const historyWidth = historyContainerRef.current?.clientWidth;

  return (
    <StyledTemplateFormWrapper
      ref={historyContainerRef}
      className="TemplateFormWrapper"
    >
      {isLoading ? (
        <Loader isCentered isInner />
      ) : (
        <TemplateForm
          templateData={data}
          type={type}
          id={id}
          brandingsUsed={brandingsUsed}
        />
      )}

      {templateHistoryState && data?.comments && (
        <TemplateHistory
          onClose={onCloseTemplateHistory}
          width={historyWidth}
          history={data.comments}
        />
      )}
    </StyledTemplateFormWrapper>
  );
};
