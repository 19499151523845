import { Row } from 'react-table';

import { SwapRows } from '../components';

type TCellProps = {
  row: Row<object>;
  rows: Row<object>[];
};

export const addSwapRowsColumn = (hooks, swapRows, onSwapRows) =>
  hooks.visibleColumns.push((allColumns) => [
    {
      disableFilters: true,
      disableSortBy: true,
      hideInMenu: true,
      id: 'swapRows',
      minWidth: 65,
      width: 65,
      Cell: ({ row, rows }: TCellProps) => (
        <SwapRows
          onSwapRows={onSwapRows}
          row={row}
          rows={rows}
          swapRowDown={swapRows.swapRowDown}
          swapRowUp={swapRows.swapRowUp}
        />
      ),
      ...swapRows.headerOptions,
    },
    ...allColumns,
  ]);
