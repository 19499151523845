import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ShortAllowanceStatus = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 22l-4 4-4-4M10 6v20M18 10l4-4 4 4M22 26V6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x={-15.5}
            y={-8127.5}
            width={207}
            height={10095}
            rx={4.5}
            stroke="currentColor"
            strokeDasharray="10 5"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 24l-4 4-4-4M10 8v20M18 8l4-4 4 4M22 24V4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 24l-4 4-4-4M10 8v20M18 8l4-4 4 4M22 24V4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14 22l-4 4-4-4M10 6v20M18 10l4-4 4 4M22 26V6" />
          </g>
          <rect
            x={-159.5}
            y={-8128.5}
            width={207}
            height={10095}
            rx={4.5}
            stroke="currentColor"
            strokeDasharray="10 5"
          />
        </svg>
      }
      {...restProps}
    />
  );
};

export default ShortAllowanceStatus;
