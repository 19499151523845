import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IColumn } from 'react-ui-kit-exante';

import { Flags } from '../../components/TranslationInfo/TranslationInfo.helpers';
import { Locales } from '../../constants/common';
import { EVENTS_PATH } from '../../routes';
import { TemplateTranslationInfo } from '../../services/shaper/translations/translations.types';
import { CellValue } from '../../types/table';

import { StyledTranslationsTableHeader } from './Translations.styled';

export const hiddenColumns = ['Id', 'Event_id'];

export const columns: IColumn<TemplateTranslationInfo>[] = [
  {
    Header: 'Id',
    accessor: 'pk',
    disableFilters: true,
  },
  {
    Header: 'Event_id',
    accessor: 'event_id',
    disableFilters: true,
  },
  {
    Header: 'Template',
    accessor: 'name',
    required: true,
    disableFilters: true,
    Cell: ({ row: { values } }: CellValue<TemplateTranslationInfo>) => {
      const navigate = useNavigate();
      if (!values?.event_id) {
        return values.name || '–';
      }
      return (
        <Button
          color="transparent"
          textColor="action"
          onClick={() =>
            navigate(
              `${EVENTS_PATH}/${values.event_id}?templateId=${values.pk}`,
            )
          }
        >
          {values.name}
        </Button>
      );
    },
  },
  {
    Header: 'Branding',
    accessor: 'brand',
    disableFilters: true,
  },
  ...Object.values(Locales).map((locale) => ({
    Header: locale,
    HeaderWithIcon: () => {
      const Flag: FC = Flags[locale];
      return (
        <StyledTranslationsTableHeader>
          <Flag />
          {locale}
        </StyledTranslationsTableHeader>
      );
    },
    accessor: `trans_ratios.${locale}.percents` as 'trans_ratios',
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 60,
  })),
];
