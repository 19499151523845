import { ReactElement, createContext, useMemo, useState } from 'react';

import { DEFAULT_PAGE } from 'constants/common';

type TPageState = {
  pageFrom: string;
  filterParams: string;
  setPageFrom(newPageFrom: string): void;
  setFilterParams(newFilterParams: string): void;
};

type TPageProviderProps = {
  children: ReactElement;
};

const initialState = {
  pageFrom: DEFAULT_PAGE,
  filterParams: '',
  setPageFrom() {},
  setFilterParams() {},
};

export const PageContext = createContext<TPageState>(initialState);

export const PageProvider = ({
  children,
}: TPageProviderProps): ReactElement | null => {
  const [pageFrom, setPageFrom] = useState<string>(initialState.pageFrom);
  const [filterParams, setFilterParams] = useState<string>(
    initialState.filterParams,
  );

  const handleSetPageFrom = (newPageFrom: string) => {
    setPageFrom(newPageFrom);
  };

  const handleFilterParamsFrom = (newFilterParams: string) => {
    setFilterParams(newFilterParams);
  };

  const value = useMemo<TPageState>(
    () => ({
      pageFrom,
      filterParams,
      setPageFrom: handleSetPageFrom,
      setFilterParams: handleFilterParamsFrom,
    }),
    [pageFrom, setPageFrom, filterParams, setFilterParams],
  );

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};
