import { TDefaultTheme } from '../../../theme';

import { IconButtonProps } from './types';

export const TRANSIENT_PROPS = [
  'iconSize',
  'iconName',
  'iconSize',
  'iconColor',
  'color',
];

export const defaultIconColors: Partial<
  Record<IconButtonProps['iconName'], keyof TDefaultTheme['color']['icon']>
> = {
  AddIcon: 'action',
  DeclineIcon: 'action',
  SaveIcon: 'action',
  PlayIcon: 'action',
  CloseIcon: 'secondary',
  UploadIcon: 'action',
  DownloadIcon: 'secondary',
  SearchIcon: 'secondary',
  ExportIcon: 'secondary',
};
