import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FullscreenOverlayStyled = styled(Dialog)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  outline: 'none',
  width: '100vw',

  '.MuiDialog-paper': {
    backgroundColor: theme?.color?.modal?.bg,
    color: theme?.color?.typo?.primary,
  },

  '& > div': {
    '&:focus': {
      outline: 'none',
    },
  },
}));
