import { styled } from 'react-ui-kit-exante';

export const StyledFormArrayFieldsWrapper = styled('div')`
  margin-right: 24px;
`;

export const StyledFormArrayFieldContainer = styled('div')`
  display: flex;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 16px;
`;
