import { createContext, ReactElement, useMemo, useState } from 'react';

import { TabType } from './TemplatesTabs.types';

type TabsState = {
  tabs: TabType;
  setTabs: (value: TabType) => void;
};

const initialState: TabsState = {
  tabs: {},
  setTabs: () => {},
};

export const TabsContext = createContext<TabsState>(initialState);

type Props = {
  children: ReactElement;
};

export const TemplateTabsProvider = ({
  children,
}: Props): ReactElement | null => {
  const [tabs, setTabs] = useState<TabType>({});

  const value = useMemo(() => ({ tabs, setTabs }), [tabs]);

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};
