export enum TooltipTexts {
  IS_DENYING_EXECUTION = 'notification event is denied with 404 error, so event initiator can handle it by itself',
  IS_MUTED = 'notification event is accepted by service but no email sent if corresponding template is muted',
  IS_SMS_AS_FALLBACK = 'If True Then SMS will be send by gateways-sns as a fallback for push message',
  SUBJECT_HINT = 'Email subject (can be overwritten by notification event.parameters.subject)',
}

export enum Locales {
  en = 'en',
  ru = 'ru',
  de = 'de',
  es = 'es',
  pt = 'pt',
  zhCn = 'zh-cn',
  zhHans = 'zh-hans',
  it = 'it',
  pl = 'pl',
  nl = 'nl',
  cs = 'cs',
  fr = 'fr',
}

export const DEFAULT_TABLE_PAGE_SIZE = 20;

export enum Notifications {
  TemplateType = 'Template',
  MailingType = 'Mailing',
  EventType = 'Event',
  SuccessSave = 'Successfully saved',
  SuccessSent = 'Successfully sent',
  ErrorSent = 'muted and not sent',
  SuccessDelete = 'Successfully deleted',
  SuccessUnarchive = 'Successfully unarchived',
}

export const FORM_DELAY = 1500;
export const DEFAULT_EMPTY_PLACEHOLDER = '–';
export const TEMPLATE_COPY_PREFIX = 'copy_';

export enum ChannelType {
  Email = 'email',
  Phone = 'phone',
  Internal = 'internalCA',
  Terminal = 'terminal',
}

export const PAGES = {
  TEMPLATES: 'templates',
  EVENTS: 'events',
  MAILINGS: 'mailings',
  TEMPLATES_ARCHIVE: 'templatesArchive',
} as const;

export const DEFAULT_PAGE = PAGES.EVENTS;
