import qs from 'qs';

import { TCommonParams } from '../../types/api/types';
import { removeEmptyStrings } from '../../utils/removeEmptyStrings';

export function paramsSerializer(p: Record<string, unknown>) {
  const paramsWithoutEmptyStrings = removeEmptyStrings(p);

  return qs.stringify(paramsWithoutEmptyStrings, {
    arrayFormat: 'comma',
    encode: false,
    skipNulls: true,
  });
}

export function getSortingParam(params: TCommonParams) {
  return {
    ...(params.sorting.length && {
      ordering: `${params.sorting[0].desc ? '-' : ''}${params.sorting[0].id}`,
    }), // TODO fix ordering on asc/desc after https://jira.exante.eu/browse/NS-74
  };
}
