import { FilterValue } from 'react-ui-kit-exante';

import { ChannelType } from 'constants/common';

import { transformVariantsToSelectOptions } from '../../../utils/transformVariantsToSelectOptions';

export type FiltersState = {
  [key in FilterType]?: string[];
};

type GetAdditionalFilters = {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  filterOptions?: FiltersState;
};

export enum FilterType {
  Search = 'search',
  TemplateName = 'name__icontains',
  TemplateIsMuted = 'is_muted',
  Branding = 'brandings__overlap',
  EventName = 'event__name__icontains',
  EventTagsName = 'event__tags__name',
  StatsDateRange = 'stats_range',
  GroupIn = 'group__in',
  Channels = 'type',
}

export type FiltersParams = {
  [FilterType.TemplateName]: string[];
  [FilterType.TemplateIsMuted]: boolean;
  [FilterType.Branding]: string[];
  [FilterType.EventName]: string[];
  [FilterType.EventTagsName]: string[];
  [FilterType.StatsDateRange]: string;
  [FilterType.GroupIn]: string[];
  [FilterType.Channels]: string;
};

export const typeVariants = Object.values(ChannelType);

export const getDefaultFilters = () => ({});

export function getAdditionalFilters({
  onFilter,
  onRemove,
  filterOptions,
}: GetAdditionalFilters) {
  return [
    {
      Header: 'Context search',
      accessor: FilterType.Search,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Template name',
      accessor: FilterType.TemplateName,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Template is muted',
      accessor: FilterType.TemplateIsMuted,
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Templates Brandings',
      accessor: FilterType.Branding,
      filterOptions: transformVariantsToSelectOptions(
        filterOptions?.brandings__overlap,
      ),
      type: 'multiSelect',
      onFilter,
      onRemove,
    },
    {
      Header: 'Event name',
      accessor: FilterType.EventName,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Tags',
      accessor: FilterType.EventTagsName,
      filterOptions: transformVariantsToSelectOptions(
        filterOptions?.event__tags__name,
      ),
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'Group',
      accessor: FilterType.GroupIn,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Channels',
      accessor: FilterType.Channels,
      filterOptions: transformVariantsToSelectOptions(typeVariants),
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'Delivery date range',
      accessor: FilterType.StatsDateRange,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
  ];
}
