import { FC } from 'react';

import { ReactComponent as CsIcon } from '../../assets/i/flags/cs.svg';
import { ReactComponent as DeIcon } from '../../assets/i/flags/de.svg';
import { ReactComponent as EnIcon } from '../../assets/i/flags/en.svg';
import { ReactComponent as EsIcon } from '../../assets/i/flags/es.svg';
import { ReactComponent as FrIcon } from '../../assets/i/flags/fr.svg';
import { ReactComponent as ItIcon } from '../../assets/i/flags/it.svg';
import { ReactComponent as NlIcon } from '../../assets/i/flags/nl.svg';
import { ReactComponent as PlIcon } from '../../assets/i/flags/pl.svg';
import { ReactComponent as PtIcon } from '../../assets/i/flags/pt.svg';
import { ReactComponent as RuIcon } from '../../assets/i/flags/ru.svg';
import { ReactComponent as ZnCnIcon } from '../../assets/i/flags/zh-cn.svg';
import { ReactComponent as ZnHansIcon } from '../../assets/i/flags/zh-hans.svg';
import { Locales } from '../../constants/common';

export const Flags: { [key in Locales]: FC } = {
  [Locales.en]: EnIcon,
  [Locales.ru]: RuIcon,
  [Locales.de]: DeIcon,
  [Locales.es]: EsIcon,
  [Locales.pt]: PtIcon,
  [Locales.zhCn]: ZnCnIcon,
  [Locales.zhHans]: ZnHansIcon,
  [Locales.it]: ItIcon,
  [Locales.pl]: PlIcon,
  [Locales.nl]: NlIcon,
  [Locales.cs]: CsIcon,
  [Locales.fr]: FrIcon,
};
