import { styled } from 'react-ui-kit-exante';

export const StyledLine = styled('div')(({ theme }) => ({
  height: '1px',
  width: '100%',
  backgroundColor: theme?.color?.bg?.basic,
}));

export const PushCardStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme?.color?.dropdown?.border,
  backdropFilter: 'blur(80px)',
  borderRadius: '16px',
  padding: '10px',
  width: '355px',
  marginBottom: '24px',
  height: '60px',
}));

export const PushCardLabelStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  color: theme?.color?.typo?.secondary,
  whiteSpace: 'nowrap',
  marginLeft: '20px',
  fontSize: '13px',
}));

export const PushBodyStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '58px',
  overflow: 'hidden',
}));

export const PushIconStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '20px',
  alignItems: 'center',
  color: theme?.color?.typo?.secondary,
  marginRight: '20px',
}));

export const PushTitleStyled = styled('div')(() => ({
  fontWeight: 600,
  fontSize: '15px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  margin: 'auto 0',
  width: '100%',
}));

export const SmsCardStyled = styled('div')(({ theme }) => ({
  color: theme?.color?.typo?.primary,
  backgroundColor: theme?.color?.dropdown?.border,
  fontSize: '15px',
  backdropFilter: 'blur(80px)',
  borderRadius: '16px',
  width: '274px',
  maxHeight: '508px',
  minHeight: '200px',
  overflowY: 'scroll',
  padding: '10px',
}));

export const StyledPhonePushContainer = styled('div')`
  padding-top: 54px;
`;

export const StyledPhoneSmsContainer = styled('div')`
  padding-top: 54px;
`;

export const StyledPhoneContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
