import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { Checkbox } from '../../../Inputs/Checkbox';
import { Tooltip } from '../../../Tooltip';
import { useBulkActions } from '../../contexts/BulkActionsContext';
import { IndeterminateInputProps } from '../../types';

export const IndeterminateCheckboxHead = forwardRef(
  <Data extends object>(
    { header, indeterminate, ...rest }: IndeterminateInputProps<Data>,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;
    const isAllRowsSelected = header?.getToggleAllRowsSelectedProps().checked;
    const bulkActions = useBulkActions();

    const handleOnClick = useCallback(
      (e) => {
        if (bulkActions?.disabledAllSelect) {
          return;
        }
        if (isAllRowsSelected) {
          if (bulkActions?.setIsSelectAll) {
            bulkActions.setIsSelectAll(false);
          }
          bulkActions.setSelectedRows([]);
        } else if (bulkActions.setIsSelectAll) {
          bulkActions.setIsSelectAll(true);
          bulkActions.setSelectedRows([]);
        } else {
          bulkActions.setSelectedRows(header.columns[0].filteredRows);
        }

        header.toggleAllRowsSelected();
        e.stopPropagation();
      },
      [bulkActions, isAllRowsSelected, header],
    );

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Tooltip title={bulkActions?.tooltipAllSelect || ''}>
        <Checkbox
          {...rest}
          ref={resolvedRef}
          onClick={handleOnClick}
          checked={isAllRowsSelected || false}
          disabled={bulkActions?.disabledAllSelect}
        />
      </Tooltip>
    );
  },
);

IndeterminateCheckboxHead.displayName = 'IndeterminateCheckboxHead';
