import { TEventDetail, TEventForm } from 'services/shaper/events/events.types';

export function getEventForm(formData?: TEventDetail | null): TEventForm {
  return {
    event: {
      name: formData?.name || '',
      description: formData?.description || '',
      tags: formData?.tags || [],
      group: formData?.group || '',
      script: formData?.script || '',
    },
    template: null,
  };
}
