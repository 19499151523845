import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Freeze = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 10.545v10.909M13.954 8.5L16 10.545 18.045 8.5M13.954 23.5L16 21.456l2.045 2.046M11.278 13.271l9.443 5.455M8.483 14.023l2.795-.75-.75-2.796M21.472 21.523l-.75-2.796 2.795-.75M11.278 18.726l9.443-5.454M10.528 21.523l.75-2.796-2.795-.75M23.517 14.023l-2.795-.75.75-2.796"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M20.724 18.727l-9.447-5.454M23.518 17.979l-2.794.748.749 2.794M10.527 10.478l.749 2.794-2.794.75M20.724 13.275l-9.445 5.451M21.47 10.478l-.747 2.796 2.795.748M8.481 17.977l2.796.749-.748 2.796M16 10.548l.002 10.905M13.953 8.5l2.046 2.047L18.046 8.5M13.954 23.499L16 21.453l2.046 2.047"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.608 10C2.294 15.74 4.26 23.078 10 26.392S23.079 27.739 26.392 22C29.706 16.26 27.74 8.92 22 5.607 16.26 2.294 8.921 4.26 5.608 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M20.724 18.727l-9.447-5.454M23.518 17.979l-2.794.748.749 2.794M10.527 10.478l.749 2.794-2.794.75M20.724 13.275l-9.445 5.451M21.47 10.478l-.747 2.796 2.795.748M8.481 17.977l2.796.749-.748 2.796M16 10.548l.002 10.905M13.953 8.5l2.046 2.047L18.046 8.5M13.954 23.499L16 21.453l2.046 2.047"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.608 10C2.294 15.74 4.26 23.078 10 26.392c5.74 3.314 13.079 1.347 16.392-4.392C29.706 16.26 27.74 8.92 22 5.607 16.26 2.294 8.921 4.26 5.608 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M16 10.545v10.909M13.954 8.5L16 10.545 18.045 8.5M13.954 23.5L16 21.456l2.045 2.046M11.278 13.271l9.443 5.455M8.483 14.023l2.795-.75-.75-2.796M21.472 21.523l-.75-2.796 2.795-.75M11.278 18.726l9.443-5.454M10.528 21.523l.75-2.796-2.795-.75M23.517 14.023l-2.795-.75.75-2.796"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
              strokeMiterlimit={10}
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Freeze;
