import { IAceEditorProps } from 'react-ace';

export enum EditorTheme {
  Default = 'twilight',
  Light = 'github',
}

export enum EditorMode {
  HTML = 'html',
  Javascript = 'javascript',
}

export const DEFAULT_EDITOR_PARAMS: IAceEditorProps = {
  mode: EditorMode.HTML,
  theme: EditorTheme.Default,
  editorProps: { $blockScrolling: true },
  fontSize: 13,
  width: '100%',
  showPrintMargin: false,
  setOptions: { useWorker: false },
};
