import { styled, Panel } from 'react-ui-kit-exante';

export const StyledEventFormActions = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledEditEventPanel = styled(Panel)`
  .PanelTitle {
    margin-bottom: 24px;
  }
`;
