import { Autocomplete as MUIAutoComplete, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ComponentType } from 'react';

import { blockNonNativeProps } from '../../../helpers';

import { IAutocompleteProps, StyledPopperProps } from './types';

export const AutoCompleteStyled = styled(MUIAutoComplete, {
  shouldForwardProp: blockNonNativeProps(['warning', 'floating']),
})<IAutocompleteProps<any, boolean, boolean, boolean>>(
  ({ warning, theme, floating }) => ({
    '& .MuiAutocomplete-inputRoot': {
      padding: floating ? '17px 12px 5px' : '5px 12px',
      minHeight: floating ? 'auto' : '48px',
      borderColor: warning && theme.color.input.warning,
      gap: 3,

      '&.MuiInputBase-sizeSmall': {
        padding: '7px',

        '& .MuiAutocomplete-endAdornment': {
          transform: 'scale(.7)',
          right: 9,
        },
      },
    },

    '.MuiInputLabel-filled.Mui-focused, .MuiInputLabel-shrink': {
      display: floating ? 'block' : 'none',
    },

    '& .MuiFilledInput-root .MuiFilledInput-input': {
      padding: 0,
      '&.MuiInputBase-inputSizeSmall': {
        padding: 0,
      },
    },

    '& .MuiFilledInput-root .MuiAutocomplete-endAdornment': {
      right: 15,
    },

    '& .MuiAutocomplete-clearIndicator': {
      padding: 2,
    },

    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 12px)',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },

    '& .MuiChip-root': {
      height: floating ? '18px' : '24px',
      backgroundColor: theme.color.dropdown.chip.bg,
      borderRadius: 4,
      padding: '3px 0',
      margin: 0,
      '&.MuiChip-sizeSmall': {
        margin: '1px',
        height: '14px',
        fontSize: '12px',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '12px',
      },
      '& .MuiChip-label': {
        color: warning ? theme.color.input.warning : theme.color.typo.primary,
        padding: '0 8px',
        lineHeight: '12px',
      },
      '& .MuiChip-deleteIcon': {
        color: `${theme.color.typo.secondary}`,
        '&:hover': {
          color: `${theme.color.typo.secondary}`,
        },
      },
    },
    '& .MuiAutocomplete-popupIndicator:hover, & .MuiAutocomplete-clearIndicator:hover':
      {
        background: 'none',
      },
  }),
);

export const StyledPopper = styled(Popper)<StyledPopperProps>(
  ({ theme, size }) => ({
    '.MuiAutocomplete-paper': {
      backgroundColor: theme?.color.dropdown.bg.default,
      color: theme?.color.typo.secondary,
      fontSize: size === 'small' ? '12px' : '15px',
      lineHeight: '24px',
      borderRadius: '0 0 4px 4px',
      '.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: theme?.color.dropdown.list.bg.focus,
      },
      '.MuiAutocomplete-option, .MuiAutocomplete-option[aria-selected="true"]':
        {
          padding: '8px 12px',
          '&.Mui-focused': {
            backgroundColor: theme?.color.dropdown.list.bg.focus,
            color: theme?.color.typo.promo,
            '&:hover': {
              backgroundColor: theme?.color.dropdown.list.bg.hover,
            },
          },
          '&:hover': {
            backgroundColor: theme?.color.dropdown.list.bg.hover,
            color: theme?.color.typo.promo,
          },
        },
    },

    '& .MuiAutocomplete-listbox': {
      padding: 0,
    },
  }),
) as ComponentType<StyledPopperProps>;
