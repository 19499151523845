import { ComponentType } from 'react';
import { styled } from 'react-ui-kit-exante';

import { StyledTranslationProps } from './TranslationInfo.types';

export const StyledTranslationWrapper = styled('div')``;

export const StyledTranslations = styled('div')`
  display: flex;
  overflow-x: auto;
  margin-bottom: 16px;
`;

export const StyledTranslationHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledTranslationCountry = styled('span')`
  display: flex;
  gap: 8px;
`;

export const StyledIconFlag = styled('span')`
  display: flex;
  align-items: center;

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledTranslationTitle = styled('h4')`
  font-family: ${({ theme }) => theme.font.header};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const StyledTranslation = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, color, isActive, disabled }: StyledTranslationProps) => ({
  backgroundColor: color,
  color: theme?.color?.typo?.inverse,
  cursor: disabled ? 'default' : 'pointer',
  opacity: disabled ? 0.8 : 1,
  border: isActive ? `1.5px solid ${theme?.color?.typo?.action}` : 'none',
  display: 'flex',
  padding: '8px 16px',
  gap: '15px',
  whiteSpace: 'nowrap',
  '&:first-of-type': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  '&:last-of-type': {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
})) as ComponentType<React.PropsWithChildren<StyledTranslationProps>>;
