import { IColumn } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from '../../constants/common';
import { TemplateListItem } from '../../services/shaper/templates/templates.types';
import { CellValue } from '../../types/table';
import { formatDateWithTime } from '../../utils/date';

export const hiddenColumns = ['Event Id'];

export function getColumns(): IColumn<TemplateListItem>[] {
  return [
    { Header: 'Event Id', accessor: 'event_id', disableFilters: true },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 80,
      disableFilters: true,
    },
    {
      Header: 'Event',
      accessor: 'event_name',
      minWidth: 80,
      disableFilters: true,
    },
    {
      Header: 'Tags',
      accessor: 'event_tags',
      disableFilters: true,
      minWidth: 60,
      disableSortBy: true,
    },
    {
      Header: 'Brandings',
      accessor: 'brandings',
      disableFilters: true,
      minWidth: 60,
      disableSortBy: true,
    },
    {
      Header: 'Group',
      accessor: 'event_group',
      disableFilters: true,
      disableSortBy: true,
      minWidth: 80,
    },
    {
      Header: 'Created date',
      accessor: 'created',
      disableFilters: true,
      minWidth: 120,
      Cell: ({ row: { values } }: CellValue<TemplateListItem>) => {
        return values.created
          ? formatDateWithTime(values.created)
          : DEFAULT_EMPTY_PLACEHOLDER;
      },
    },
    {
      Header: 'Modified date',
      accessor: 'modified',
      disableFilters: true,
      minWidth: 120,
      Cell: ({ row: { values } }: CellValue<TemplateListItem>) => {
        return values.modified
          ? formatDateWithTime(values.modified)
          : DEFAULT_EMPTY_PLACEHOLDER;
      },
    },
    {
      Header: 'Channels',
      accessor: 'type',
      disableSortBy: true,
      disableFilters: true,
      minWidth: 120,
    },
    {
      Header: 'Is Muted',
      accessor: 'is_muted',
      disableFilters: true,
      minWidth: 60,
      maxWidth: 80,
    },
    {
      Header: 'Read',
      accessor: 'acked_count',
      disableFilters: true,
      minWidth: 60,
      maxWidth: 80,
      Cell: ({ row: { values } }: CellValue<TemplateListItem>) =>
        values.acked_count || '—',
    },
    {
      Header: 'Delivered',
      accessor: 'sent_count',
      disableFilters: true,
      minWidth: 60,
      maxWidth: 80,
      Cell: ({ row: { values } }: CellValue<TemplateListItem>) =>
        values.sent_count || '—',
    },
    {
      Header: 'Links Opened',
      accessor: 'clicks_count',
      disableFilters: true,
      minWidth: 60,
      maxWidth: 80,
      Cell: ({ row: { values } }: CellValue<TemplateListItem>) =>
        values.clicks_count || '—',
    },
  ];
}
