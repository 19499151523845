import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { UseColumnOrderInstanceProps } from 'react-table';

import type { IColumn, TColumnResetType } from '../../types';

import {
  getDefaultColumnKeys,
  getHiddenColumns,
  getKeysByColumns,
  getPredefinedColumnKeys,
  getRequiredColumnKeys,
} from './utils';

interface IUseDisplayedColumnsKeysProps<Data extends object>
  extends UseColumnOrderInstanceProps<Data> {
  resetResizing(): void;
  displayedColumnKeys?: string[];
  tableId: string;
  tableLSWidthKey: string;
  setHiddenColumns(columns: string[]): void;
  columns: IColumn<Data>[];
}

export function useColumnsKeys<Data extends object>({
  resetResizing,
  displayedColumnKeys,
  tableId,
  tableLSWidthKey,
  setHiddenColumns,
  columns,
}: IUseDisplayedColumnsKeysProps<Data>) {
  const tableLSColumnsKey = `${tableId}-columns`;
  const lsColumnsData = localStorage.getItem(tableLSColumnsKey);
  const requiredColumnKeys = useMemo(
    () => getRequiredColumnKeys(columns),
    [columns],
  );
  const defaultColumnKeys = useMemo(
    () =>
      getDefaultColumnKeys(columns, requiredColumnKeys, displayedColumnKeys),
    [columns, displayedColumnKeys, requiredColumnKeys],
  );
  const predefinedColumnKeys = useMemo(
    () =>
      getPredefinedColumnKeys({
        lsColumnsData,
        defaultColumnKeys,
        columns,
        requiredColumnKeys,
      }),
    [lsColumnsData, defaultColumnKeys, columns, requiredColumnKeys],
  );

  const [visibleColumnKeys, setVisibleColumnKeys] = useState<string[]>(
    () => predefinedColumnKeys,
  );

  const hiddenColumns = useMemo(
    () => getHiddenColumns(columns, visibleColumnKeys),
    [visibleColumnKeys, columns],
  );

  const handleVisibleColumnKeysChange = useCallback(
    (key: string, checked: boolean) => {
      setVisibleColumnKeys((prev) => {
        const newKeys = checked
          ? [...prev, key]
          : prev.filter((column) => column !== key);

        localStorage.setItem(tableLSColumnsKey, JSON.stringify(newKeys));

        return newKeys;
      });
    },
    [tableLSColumnsKey],
  );

  const resetColumnKeys = useCallback(
    (type: TColumnResetType = 'default') => {
      localStorage.removeItem(tableLSWidthKey);
      resetResizing();

      let columnKeys = getKeysByColumns(columns);

      if (type === 'none') {
        columnKeys = [];
      }

      if (type === 'default') {
        if (defaultColumnKeys?.length) {
          columnKeys = defaultColumnKeys;
        }
      }

      localStorage.setItem(tableLSColumnsKey, JSON.stringify(columnKeys));
      setVisibleColumnKeys(columnKeys);
    },
    [
      columns,
      defaultColumnKeys,
      resetResizing,
      tableLSColumnsKey,
      tableLSWidthKey,
    ],
  );

  useLayoutEffect(() => {
    setVisibleColumnKeys(predefinedColumnKeys);
  }, [predefinedColumnKeys]);

  useLayoutEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  return {
    resetColumnKeys,
    handleVisibleColumnKeysChange,
    visibleColumnKeys,
  };
}
