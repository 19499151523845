import { styled } from 'react-ui-kit-exante';

export const StyledMetadataLoader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledMetadataHeader = styled('div')(({ theme }) => ({
  backgroundColor: '#4E5D60',
  color: theme.color?.typo?.inverse,
  padding: '24px 65px',
  fontSize: '20px',
  lineHeight: '32px',
}));
