import { createContext, FC, ReactNode, useState } from 'react';
import { Modal, useModal } from 'react-ui-kit-exante';

export const LeaveModalContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showModal: (fn: VoidFunction) => {},
});

const { Provider } = LeaveModalContext;

export const LeaveModalContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const conformLeaveModal = useModal();
  const [callbackFn, setCallbackFn] = useState<VoidFunction>(() => {});

  const showModal = (fn: VoidFunction) => {
    setCallbackFn(fn);
    conformLeaveModal.onOpen();
  };

  const onSubmitHandler = () => {
    callbackFn();
    conformLeaveModal.onClose();
  };

  return (
    <Provider
      value={{
        showModal,
      }}
    >
      {children}
      <Modal
        isOpened={conformLeaveModal.isOpened}
        onClose={conformLeaveModal.onClose}
        title="Confirm action"
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: onSubmitHandler,
        }}
      >
        <div>Unsaved data will be lost</div>
      </Modal>
    </Provider>
  );
};
