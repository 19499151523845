import { Theme, ThemeProvider } from '@mui/material';
import { merge } from 'lodash';
import { FC, ReactNode, useCallback } from 'react';

import { defaultTheme } from './theme';

export type TDefaultThemeProviderProps = {
  children?: ReactNode;
  theme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
};

export const DefaultThemeProvider: FC<TDefaultThemeProviderProps> = ({
  theme: propsTheme = {},
  children,
}: TDefaultThemeProviderProps) => {
  const themePicker = useCallback(
    (outerTheme: Theme): Theme => {
      let chosenTheme = defaultTheme;

      if (
        typeof outerTheme === 'object' &&
        Object.keys(outerTheme).length > 0
      ) {
        chosenTheme = outerTheme;
      }

      return merge({}, chosenTheme, propsTheme);
    },
    [propsTheme],
  );

  return <ThemeProvider theme={themePicker}>{children}</ThemeProvider>;
};
