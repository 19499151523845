import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  Tab,
  Tabs,
  Modal,
  useModal,
  Notification,
  TabPanel,
  ISelectOption,
} from 'react-ui-kit-exante';

import { EVENT_CREATE_PATH } from 'routes/constants';

import { Notifications, TEMPLATE_COPY_PREFIX } from '../../../constants/common';
import { SettingsContext } from '../../../contexts/SettingsContext';
import { useQuery } from '../../../hooks/useQuery';
import { TEventForm } from '../../../services/shaper/events/events.types';
import { TypesVerbose } from '../../../services/shaper/settings/settings.types';
import { deleteTemplateReq } from '../../../services/shaper/templates/templates.requests';
import { LabelTab } from '../../LabelTab/LabelTab';
import { EventInfo } from '../EventInfo/EventInfo';
import { Template } from '../Template/Template';
import { LeaveModalContext } from '../TemplateForm/contexts/ConfirmLeaveContext';
import { TemplateHeader } from '../TemplateHeader/TemplateHeader';

import { INFO_TAB } from './TemplateTabs.consts';
import { TabsContext } from './TemplateTabsContext';
import { EventTab, TabType, TemplatesTabsProps } from './TemplatesTabs.types';

export const TemplatesTabs: FC<TemplatesTabsProps> = ({
  channels = [],
  eventDataFetch,
  isEventEditable,
}) => {
  const query = useQuery();
  const deleteModal = useModal();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const { showModal } = useContext(LeaveModalContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const { tabs, setTabs } = useContext(TabsContext);
  const { typesVerbose } = useContext(SettingsContext);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    query.get('templateId'),
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTemplateHistoryOpen, setTemplateHistoryOpen] = useState(false);
  const [deletingData, setDeletingData] = useState<string | null>(null);

  const eventTabs: EventTab[] = [INFO_TAB, ...typesVerbose];

  const isNewEvent = location.pathname.includes(EVENT_CREATE_PATH);

  const brandingsUsed = useMemo(
    () =>
      channels.reduce(
        (result: string[], channel) => result.concat(channel.brandings),
        [],
      ),
    [channels],
  );

  const formContext = useFormContext<TEventForm>();
  const {
    reset,
    getValues,
    setValue,
    formState: { dirtyFields },
  } = formContext;

  const clearTemplate = () => {
    reset({ ...getValues(), template: null });
    setSearchParams({});
  };

  const resetTemplate = () => {
    setSelectedTemplateId(null);
    clearTemplate();
  };

  const onSelectTemplate = (name: string) => {
    clearTemplate();
    setSelectedTemplateId(name);
    setSearchParams({ templateId: name });
  };

  const calculateInitialTemplate = (tab: number) => {
    const channelTemplates = tabs[eventTabs[tab].name];
    if (!channelTemplates) {
      resetTemplate();
      return;
    }
    const initialTemplate = channelTemplates[0].name;
    onSelectTemplate(initialTemplate);
  };

  const handleChangeTab = (newValue: number) => {
    setSelectedTab(newValue);
    calculateInitialTemplate(newValue);
  };

  const preventChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (dirtyFields.template) {
      showModal(() => () => handleChangeTab(newValue));
    } else {
      handleChangeTab(newValue);
    }
  };

  useEffect(() => {
    const templateId = query.get('templateId');
    const template = channels?.find(({ name }) => name === templateId);
    const tabsList = eventTabs.map((item) => item.name);

    if (template && tabsList.length) {
      const tabIdx = tabsList.findIndex((value) => value === template.type);
      setSelectedTab(tabIdx);
    }
  }, [eventTabs]);

  useEffect(() => {
    const tabsMap = [...channels].reduce((acc, curr) => {
      if (acc[curr.type]) {
        acc[curr.type] = [...acc[curr.type], curr];
      } else {
        acc[curr.type] = [curr];
      }
      return acc;
    }, {} as TabType);

    setTabs(tabsMap);
  }, []);

  const onAddTemplate = (e: SyntheticEvent) => {
    e.preventDefault();
    clearTemplate();
    setSelectedTemplateId(null);
  };

  const onCopyTemplate = async () => {
    const { template } = getValues();
    setSearchParams({});

    if (template) {
      setValue('template.name', `${TEMPLATE_COPY_PREFIX}${template.name}`);
      setValue('template.brandings', []);
      setValue('template.branding', '');
      setValue('template.id', '');
      setValue('template.created', '');
      reset({ ...getValues() });
    }
  };

  const onSelectTemplatePrevent = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    if (selectedTemplateId === name) {
      return;
    }
    if (dirtyFields.template) {
      showModal(() => () => onSelectTemplate(name));
    } else {
      onSelectTemplate(name);
    }
  };

  const deleteClickHandler = () => {
    if (!selectedTemplateId) {
      return;
    }
    deleteModal.onOpen();
    setDeletingData(selectedTemplateId);
  };

  const openTemplateHistory = () => {
    setTemplateHistoryOpen(true);
  };

  const closeTemplateHistory = () => {
    setTemplateHistoryOpen(false);
  };

  const onCloseDeleteModalHandler = useCallback(() => {
    deleteModal.onClose();
    setDeletingData(null);
    setIsDeleting(false);
  }, []);

  const onDeleteConfirmHandler = useCallback(async () => {
    const successMessage = `${Notifications.TemplateType} is ${Notifications.SuccessDelete}`;

    if (deletingData) {
      setIsDeleting(true);
      try {
        await deleteTemplateReq(deletingData);
        Notification.success({ title: successMessage });
      } catch (error: any) {
        Notification.error(error?.message);
      } finally {
        onCloseDeleteModalHandler();
        if (eventDataFetch) {
          await eventDataFetch();
        }
      }
    }
  }, [deletingData]);

  return (
    <>
      <>
        <Tabs
          value={selectedTab}
          onChange={preventChangeTab}
          className="TemplatesTabs"
          variant="scrollable"
        >
          {eventTabs.map(({ name, title }, index) => {
            const infoTab = name === INFO_TAB.name;
            const templatesCount = infoTab
              ? undefined
              : tabs[name]?.length || 0;

            const tabLabel = (
              <LabelTab
                label={title}
                counterValue={templatesCount}
                numberTab={index}
                activeTab={selectedTab}
              />
            );
            return <Tab label={tabLabel} key={name} disabled={isNewEvent} />;
          })}
        </Tabs>

        {eventTabs.map(({ name }, index) => {
          const type: TypesVerbose['name'] = name as TypesVerbose['name'];
          const templatesInTab = tabs[type] || [];
          const isInfoTab: boolean = name === INFO_TAB.name;

          const templatesList: ISelectOption[] = templatesInTab.map((item) => ({
            label: item.name,
            value: item.name,
          }));

          const selectedTemplateBranding =
            templatesInTab
              .find((channel) => channel.name === selectedTemplateId)
              ?.brandings.toString()
              .replaceAll(',', ', ') || 'all';

          return (
            <TabPanel value={selectedTab} index={index} key={name}>
              {isInfoTab && <EventInfo isEventEditable={isEventEditable} />}

              {!isInfoTab && (
                <>
                  <TemplateHeader
                    templatesList={templatesList}
                    selectedTemplateId={selectedTemplateId}
                    selectedTemplateBranding={selectedTemplateBranding}
                    onSelectTemplate={onSelectTemplatePrevent}
                    onAddTemplate={onAddTemplate}
                    onCopyTemplate={onCopyTemplate}
                    onDeleteTemplate={deleteClickHandler}
                    onOpenTemplateHistory={openTemplateHistory}
                  />
                  <Template
                    type={type}
                    id={selectedTemplateId}
                    brandingsUsed={brandingsUsed}
                    templateHistoryState={isTemplateHistoryOpen}
                    onCloseTemplateHistory={closeTemplateHistory}
                  />
                </>
              )}
            </TabPanel>
          );
        })}
      </>

      <Modal
        isOpened={deleteModal.isOpened}
        onClose={onCloseDeleteModalHandler}
        title="Are you sure?"
        confirmButton={{
          confirmButtonIsDisabled: isDeleting,
          handleConfirm: onDeleteConfirmHandler,
        }}
        isLoading={isDeleting}
      >
        <p>
          Are you sure you want to delete the template
          <br />
          <b>{deletingData}</b>?
        </p>
      </Modal>
    </>
  );
};
