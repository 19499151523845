import { RefObject, useEffect, useRef, useState } from 'react';

import type { ITableVirtualized } from '../../types';

export const useAdaptiveTableHeight = (
  isPinnedHeader: boolean | undefined,
  virtualized: ITableVirtualized,
  paginationHeight: number,
) => {
  const refTableContent: RefObject<HTMLDivElement> = useRef();
  const [heightTable, setHeightTable] = useState(null);
  const calculateHeightTable = (yDistance: number, outerHeight: number) =>
    outerHeight - yDistance - paginationHeight;

  const currentElementContent = refTableContent?.current;
  const yDistance = Math.abs(currentElementContent?.getBoundingClientRect()?.y);

  useEffect(() => {
    if (isPinnedHeader && currentElementContent && virtualized) {
      setHeightTable({
        maxHeight: calculateHeightTable(yDistance, window.outerHeight),
      });
    } else {
      setHeightTable({
        maxHeight: 'unset',
      });
    }
  }, [currentElementContent]);

  return { refTableContent, heightTable };
};
