import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IconButton, Notification, Panel } from 'react-ui-kit-exante';

import { Notifications } from '../../constants/common';
import { SettingsContext } from '../../contexts/SettingsContext';
import { MAILINGS_PATH } from '../../routes';
import { MailingsService } from '../../services/shaper/mailings';
import {
  Mailing,
  MailingFormType,
} from '../../services/shaper/mailings/mailings.types';
import { FormArrayFields } from '../Form/FormArrayFields/FormArrayFields';
import { FormInputContainer } from '../Form/FormInputContainer/FormInputContainer';
import { FormMultiSelectContainer } from '../Form/FormMultiSelectContainer/FormMultiSelectContainer';

import { MAILING_FORM_VALIDATION_SCHEMA } from './MailingForm.consts';
import { checkIsDirty, getMailingForm } from './MailingForm.helpers';
import {
  StyledMailingFormActions,
  StyledMailingFormField,
  StyledMailingFormLabel,
  StyledMailingFormMain,
} from './MailingForm.styled';

export type MailingFormProps = {
  title: string;
  mailingData?: Mailing | null;
  mailingDataFetch?: () => Promise<void>;
  id?: string;
};

export const MailingForm: FC<MailingFormProps> = ({
  title,
  mailingData,
  mailingDataFetch,
  id,
}) => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const { brandingsOptions } = useContext(SettingsContext);

  const defaultValues = getMailingForm(mailingData);

  const methods = useForm<MailingFormType>({
    defaultValues,
    resolver: yupResolver(MAILING_FORM_VALIDATION_SCHEMA),
  });

  const {
    handleSubmit,
    getValues,
    formState: { dirtyFields },
    control,
  } = methods;

  const onCloseHandler = () => {
    navigate(MAILINGS_PATH);
  };

  const onSave = async () => {
    const successMessage = `${Notifications.MailingType} is ${Notifications.SuccessSave}`;
    try {
      setIsSaving(true);
      const formData = getValues();
      const response = id
        ? await MailingsService.updateMailing(id, formData)
        : await MailingsService.createMailing(formData);

      if (response) {
        Notification.success({ title: successMessage });

        if (mailingDataFetch) {
          await mailingDataFetch();
        } else {
          navigate(`${MAILINGS_PATH}/${response.id}`);
        }
      }
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsSaving(false);
    }
  };

  const isEditable = mailingData ? mailingData.edit : true;
  const isDisabled =
    !Object.keys(dirtyFields).length ||
    checkIsDirty(dirtyFields) ||
    isSaving ||
    !isEditable;

  const buttonName = id ? 'Save' : 'Create';

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)}>
        <Panel
          title={title}
          action={
            <StyledMailingFormActions>
              <IconButton
                data-test-id="mailings-module__button--save"
                type="submit"
                iconName="SaveIcon"
                disabled={isDisabled}
                label={buttonName}
                iconColor="action"
              />
              <IconButton
                data-test-id="mailings-module__button--close"
                iconName="CloseIcon"
                onClick={onCloseHandler}
                title="Close form"
              />
            </StyledMailingFormActions>
          }
        >
          <StyledMailingFormMain>
            <FormInputContainer
              label="Name"
              name="name"
              type="text"
              fullWidth
              disabled={!isEditable}
            />
            <FormMultiSelectContainer
              label="Brandings"
              name="brandings"
              options={brandingsOptions}
              disabled={!isEditable}
              fullWidth
              className="MailingFormBrandings"
            />
            <StyledMailingFormField>
              <StyledMailingFormLabel>To:</StyledMailingFormLabel>
              <FormArrayFields
                name="recipients_to"
                control={control}
                label="To"
                disabled={!isEditable}
              />
            </StyledMailingFormField>
            <StyledMailingFormField>
              <StyledMailingFormLabel>Cc:</StyledMailingFormLabel>
              <FormArrayFields
                name="recipients_cc"
                control={control}
                label="Cc"
                disabled={!isEditable}
              />
            </StyledMailingFormField>
            <StyledMailingFormField>
              <StyledMailingFormLabel>Bcc:</StyledMailingFormLabel>
              <FormArrayFields
                name="recipients_bcc"
                control={control}
                label="Bcc"
                disabled={!isEditable}
              />
            </StyledMailingFormField>
          </StyledMailingFormMain>
        </Panel>
      </form>
    </FormProvider>
  );
};
