import { FormLayout, styled } from 'react-ui-kit-exante';

export const StyledMailingFormActions = styled('div')`
  display: flex;
  justify-content: end;
  gap: 16px;
`;

export const StyledMailingFormMain = styled(FormLayout)`
  .MailingFormBrandings {
    grid-column: 2 / -1;
  }
`;

export const StyledMailingFormField = styled('div')`
  color: ${({ theme }) => theme.color.typo.promo};
`;

export const StyledMailingFormLabel = styled('span')`
  display: block;
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.size.text.md};
`;
