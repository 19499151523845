const sortFn = (a, b) => {
  if (a > b) {
    return 1;
  }

  if (a < b) {
    return -1;
  }

  return 0;
};

const convertToNumberOrString = (value) =>
  Number.isNaN(Number(value)) ? value.toLowerCase() : Number(value);

export const sortNumeric = (
  { original: originalA },
  { original: originalB },
  accessor,
) => {
  const fieldA = convertToNumberOrString(originalA[accessor]);
  const fieldB = convertToNumberOrString(originalB[accessor]);

  return sortFn(fieldA, fieldB);
};
