import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Notification, useData, Loader } from 'react-ui-kit-exante';

import { MailingForm } from '../../components/MailingForm/MailingForm';
import { Params } from '../../routes/types';
import { MailingsService } from '../../services/shaper/mailings';
import { Mailing } from '../../services/shaper/mailings/mailings.types';

export const EditMailings: FC = () => {
  const { id } = useParams<Params>();

  const getMailing = useCallback(async () => {
    if (id) {
      try {
        return await MailingsService.getMailing(id);
      } catch (error: any) {
        Notification.error(error?.message);
      }
    }
    return null;
  }, [id]);

  const mailingData = useData<Mailing | null>({
    onFetch: getMailing,
  });

  useEffect(() => {
    mailingData.fetchData();
  }, [mailingData.fetchData]);

  const { isLoading } = mailingData;

  return isLoading ? (
    <Loader isCentered />
  ) : (
    <MailingForm
      title="Change mailing"
      mailingData={mailingData.data}
      mailingDataFetch={mailingData.fetchData}
      id={id}
    />
  );
};
