import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../helpers';

import { IconProps } from './types';

export const StyledIconWrapper = styled('span', {
  shouldForwardProp: blockNonNativeProps(['color', 'size', 'autoSize']),
})<IconProps>(({ color, size: sizeProp, autoSize }) => {
  const size = sizeProp ? `${sizeProp}px` : '16px';

  return {
    color,
    width: autoSize ? '1em' : size,
    height: autoSize ? '1em' : size,
    display: 'inline-flex',
    fontSize: 'inherit',
  };
});
