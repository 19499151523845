import { IColumn } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from '../../constants/common';
import { TEvent } from '../../services/shaper/events/events.types';
import { CellValue } from '../../types/table';
import { formatDateWithTime } from '../../utils/date';

export const hiddenColumns = ['Id'];

export function getColumns(): IColumn<TEvent>[] {
  return [
    { Header: 'Id', accessor: 'id', disableFilters: true },
    {
      Header: 'Event',
      accessor: 'name',
      minWidth: 80,
      disableFilters: true,
    },
    {
      Header: 'Tags',
      accessor: 'tags',
      disableFilters: true,
      minWidth: 60,
      disableSortBy: true,
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableFilters: true,
      disableSortBy: true,
      minWidth: 120,
    },
    {
      Header: 'Group',
      accessor: 'group',
      disableFilters: true,
      disableSortBy: true,
      minWidth: 80,
    },
    {
      Header: 'Created date',
      accessor: 'created',
      disableFilters: true,
      minWidth: 120,
      Cell: ({ row: { values } }: CellValue<TEvent>) => {
        return values.created
          ? formatDateWithTime(values.created)
          : DEFAULT_EMPTY_PLACEHOLDER;
      },
    },
    {
      Header: 'Modified date',
      accessor: 'modified',
      disableFilters: true,
      minWidth: 120,
      Cell: ({ row: { values } }: CellValue<TEvent>) => {
        return values.modified
          ? formatDateWithTime(values.modified)
          : DEFAULT_EMPTY_PLACEHOLDER;
      },
    },
    {
      Header: 'Channels',
      accessor: 'channel_types',
      Cell: ({ row: { values } }: CellValue<TEvent>) => {
        return values.channel_types.length > 0
          ? [...new Set(values.channel_types.map((type) => type))].join(', ')
          : DEFAULT_EMPTY_PLACEHOLDER;
      },
      disableSortBy: true,
      disableFilters: true,
      minWidth: 120,
    },
  ];
}
