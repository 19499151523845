import { FC } from 'react';
import { Input } from 'react-ui-kit-exante';

import { TemplatePreview } from '../../../../TemplatePreview/TemplatePreview';

import { StyledEmailViewer, StyledViewerHeader } from './EmailViewer.styled';
import { EmailViewerProps } from './EmailViewer.types';

export const EmailViewer: FC<EmailViewerProps> = ({
  isLoading,
  info = null,
}) => {
  return (
    <StyledEmailViewer className="EmailViewer">
      <StyledViewerHeader className="ViewerHeader">
        <Input label="Subject" value={info?.subject || ''} disabled fullWidth />
      </StyledViewerHeader>

      <TemplatePreview isLoading={isLoading} info={info} />
    </StyledEmailViewer>
  );
};
