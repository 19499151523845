import { Panel, styled } from 'react-ui-kit-exante';

export const StyledTranslationPreviewPanel = styled(Panel)`
  padding: 24px 0 0;
`;

export const StyledTranslationPreviewHeader = styled('div')`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme?.color?.line?.primary};
  gap: 50px;
  padding-bottom: 16px;
`;
