import { styled, FormLayout } from 'react-ui-kit-exante';

export const StyledPhoneWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledPhoneEditorWrapper = styled('div')`
  border-right: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledPhoneEditorFields = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export const StyledPhoneTemplateSettings = styled(FormLayout)`
  margin-bottom: 24px;
`;
