import { FC } from 'react';
import { useSpring } from 'react-spring';
import { IconButton, Table } from 'react-ui-kit-exante';

import { columns } from './TemplateHistory.consts';
import {
  StyledEmptyHistory,
  StyledTemplateHistory,
  TemplateHistoryTableContainer,
} from './TemplateHistory.styled';
import { Props } from './TemplateHistory.types';

export const TemplateHistory: FC<Props> = ({ onClose, width, history }) => {
  const styles = useSpring({
    to: {
      opacity: 1,
      transform: 'translateY(0)',
      width: width ? `${width}px` : '100%',
    },
    from: { opacity: 0, transform: 'translateY(50%)' },
  });

  const table = history.length ? (
    <TemplateHistoryTableContainer className="TemplateHistoryTableContainer">
      <Table
        title="Change History"
        titleSize={5}
        tableId="notification-shaper-template-history"
        data={history || []}
        columns={columns}
        defaultSortBy={[{ id: 'date_created', desc: true }]}
        isFlexLayout
        additionalActions={[
          {
            component: (
              <IconButton
                onClick={onClose}
                iconSize={24}
                iconName="CloseIcon"
                iconColor="secondary"
                title="Close history"
                data-test-id="template-history__button--close"
              />
            ),
          },
        ]}
      />
    </TemplateHistoryTableContainer>
  ) : (
    <StyledEmptyHistory>Change history is empty</StyledEmptyHistory>
  );

  return (
    <StyledTemplateHistory className="TemplateHistory" style={styles}>
      {table}
    </StyledTemplateHistory>
  );
};
