type ErrorInnerField = Record<number, string[]>;

type ErrorMessages = {
  [key: string]: string[] | ErrorInnerField;
};

export function parseErrors(message: ErrorMessages) {
  return Object.keys(message)
    .flatMap((i) => Object.values(message[i]).flat().join('; '))
    .join('; ');
}
