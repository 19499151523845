import { parse } from 'date-fns';

import { setTime } from '../TimePicker/helpers';
import { TTimePickerDefaults } from '../TimePicker/types';

export const castToDate = (
  value: string | Date | null | undefined,
  format: string,
  timeDefaults?: TTimePickerDefaults,
) => {
  const referenceDate = timeDefaults
    ? setTime(new Date(), timeDefaults)
    : new Date();

  return typeof value === 'string'
    ? parse(value, format, referenceDate)
    : value;
};
