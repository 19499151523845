import {
  FiltersParams,
  FiltersState,
  FilterType,
  typeVariants,
} from '../../../pages/Events/filters/filters';
import { TCommonParamsWithFilter } from '../../../types/api/types';
import { parseErrors } from '../../../utils/parseErrors';
import { getSortingParam } from '../../api/Api.helpers';
import { getTagsReq } from '../tags/tags.requests';

import {
  createEventReq,
  getEventReq,
  getEventsReq,
  updateEventReq,
} from './events.requests';
import {
  EventFormType,
  EventsState,
  TEvent,
  TEventDetail,
} from './events.types';

export const getEvents = async (
  params: TCommonParamsWithFilter<FiltersParams>,
  filters: FiltersParams,
): Promise<EventsState> => {
  try {
    const response = await getEventsReq({
      limit: params.limit,
      offset: params.skip,
      ...getSortingParam(params),
      ...filters,
    });

    return {
      result: response?.data?.results,
      total: response?.data?.count,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getFiltersOptions = async (
  brandings: string[],
): Promise<FiltersState> => {
  try {
    const tagsResponse = await getTagsReq();
    return {
      [FilterType.EventTagName]: tagsResponse.data.map(({ name }) => name),
      [FilterType.Branding]: brandings,
      [FilterType.Type]: typeVariants,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getEvent = async (id: string): Promise<TEventDetail> => {
  try {
    const { data } = await getEventReq(id);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createEvent = async (
  reqData: EventFormType,
): Promise<TEventDetail> => {
  try {
    const { data } = await createEventReq(reqData);
    return data;
  } catch (error: any) {
    if (error?.response?.data) {
      throw new Error(parseErrors(error?.response.data));
    } else {
      throw new Error(error);
    }
  }
};

export const updateEvent = async (
  id: string,
  reqData: EventFormType,
): Promise<TEvent> => {
  try {
    const { data } = await updateEventReq(id, reqData);
    return data;
  } catch (error: any) {
    if (error?.response?.data) {
      throw new Error(parseErrors(error?.response.data));
    } else {
      throw new Error(error);
    }
  }
};
