import { isFunction } from 'lodash';
import { useMemo } from 'react';

import { EditIcon, CloseIcon, SaveIcon } from '../../../../Icons';
import { useTheme } from '../../../../theme';
import { getColor } from '../../../Button/IconButton/helpers';
import {
  useEditableRowId,
  useEditableRowValuesContext,
} from '../../contexts/EditableContext';

import { prepareEditableRowValues } from './ActionsCell.helpers';
import { Button, Container } from './ActionsCell.styled';
import type { ActionsCellProps } from './ActionsCell.types';

export const ActionsCell = <Data extends object>({
  onSaveRow,
  onCancelEditingRow,
  rowId,
  additionalActions = [],
  values,
  columns,
  isEditedRow,
  order = 0,
  hideEdit,
}: ActionsCellProps<Data>) => {
  const { editableRowId, setEditableRowId } = useEditableRowId();
  const { editableRowValues } = useEditableRowValuesContext<Data>();
  const isEditModeOn = rowId === editableRowId;

  const theme = useTheme();
  const color = getColor(theme, 'action');

  const setEditMode = (editMode: boolean) => {
    setEditableRowId(editMode ? rowId : '');
  };

  const onSave: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setEditMode(false);

    onSaveRow(prepareEditableRowValues<Data>(editableRowValues, columns));
  };

  const onCancel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setEditMode(false);

    if (onCancelEditingRow) {
      onCancelEditingRow(
        prepareEditableRowValues<Data>(editableRowValues, columns),
      );
    }
  };

  const onEdit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setEditMode(true);
  };

  const isEditModeOff =
    !isEditModeOn &&
    (isEditedRow && isFunction(isEditedRow) ? isEditedRow(values) : true);

  const actionItems = useMemo(() => {
    const items = [
      {
        id: 'edit',
        show: isEditModeOff && !hideEdit,
        order,
        render: (
          <Button
            title="Edit"
            onClick={onEdit}
            data-test-id="table__body--action-cell-edit-button"
          >
            <EditIcon />
          </Button>
        ),
      },
      {
        id: 'save',
        order,
        show: onSaveRow && isEditModeOn,
        render: (
          <Button
            iconColor="action"
            title="Save"
            onClick={onSave}
            data-test-id="table__body--action-cell-save-button"
          >
            <SaveIcon applyStates={false} color={color} />
          </Button>
        ),
      },
      {
        id: 'cancel',
        order,
        show: onSaveRow && isEditModeOn,
        render: (
          <Button
            title="Cancel"
            onClick={onCancel}
            data-test-id="table__body--action-cell-cancel-button"
          >
            <CloseIcon />
          </Button>
        ),
      },
      ...additionalActions.map(
        ({ label, title, onClick, order: additionalActionOrder, ...rest }) => ({
          id: title,
          order: additionalActionOrder,
          show: true,
          render: (
            <Button
              key={title}
              title={title}
              onClick={(event) => {
                event.stopPropagation();
                onClick(values);
              }}
              data-test-id={`table__body--action-cell-${title}-button`}
              {...rest}
            >
              {label}
            </Button>
          ),
        }),
      ),
    ];

    return items.sort((v1, v2) => v1.order - v2.order);
  }, [
    isEditModeOn,
    onSaveRow,
    onCancelEditingRow,
    isEditedRow,
    additionalActions,
    editableRowValues,
    hideEdit,
  ]);

  return (
    <Container data-test-id="table__body--action-cell">
      {actionItems.map(({ render, show }) => show && render)}
    </Container>
  );
};
