import { FC } from 'react';
import { Loader } from 'react-ui-kit-exante';

import { ReactComponent as ExanteLogo } from '../../../../../assets/i/ExanteLogo.svg';
import { StyledErrorContainer } from '../../../../TemplatePreview/TemplatePreview.styled';

import {
  PushBodyStyled,
  PushCardLabelStyled,
  PushCardStyled,
  PushIconStyled,
  PushTitleStyled,
  SmsCardStyled,
  StyledPhoneContainer,
  StyledPhonePushContainer,
  StyledPhoneSmsContainer,
} from './PhoneViewer.styled';
import { PhoneViewerProps } from './PhoneViewer.types';

export const PhoneViewer: FC<PhoneViewerProps> = ({
  isLoading,
  info = null,
}) => {
  return (
    <StyledPhoneContainer className="PhoneContainer">
      {isLoading && <Loader isCentered />}
      {!isLoading && info?.content && (
        <>
          <StyledPhonePushContainer className="PhonePushContainer">
            <PushCardStyled>
              <PushIconStyled>
                <ExanteLogo />
              </PushIconStyled>
              <PushBodyStyled>
                <PushTitleStyled title={info?.subject}>
                  {info?.subject}
                </PushTitleStyled>
              </PushBodyStyled>
              <PushCardLabelStyled>1m ago</PushCardLabelStyled>
            </PushCardStyled>
          </StyledPhonePushContainer>
          <StyledPhoneSmsContainer className="PhoneSmsContainer">
            <SmsCardStyled dangerouslySetInnerHTML={{ __html: info.content }} />
          </StyledPhoneSmsContainer>
        </>
      )}
      <StyledErrorContainer className="ErrorContainer">
        {info?.error && <p>{info.error}</p>}
        {info?.lineno && <p>Line: {info.lineno}</p>}
      </StyledErrorContainer>
    </StyledPhoneContainer>
  );
};
