import { ISelectOption } from 'react-ui-kit-exante';

import {
  FilterType,
  FiltersParams,
  FiltersState,
} from 'pages/Templates/filters/filters';

import {
  TCommonParams,
  TCommonParamsWithFilter,
} from '../../../types/api/types';
import { parseErrors } from '../../../utils/parseErrors';
import { getSortingParam } from '../../api/Api.helpers';
import { getTagsReq } from '../tags/tags.requests';

import {
  debugNotificationReq,
  getTemplateReq,
  sendNotificationReq,
  updateTemplateReq,
  createTemplateReq,
  getArchiveTemplatesReq,
  getTemplatesReq,
  importTemplatesReq,
  exportTemplatesReq,
  exportTemplatesTextsReq,
} from './templates.requests';
import {
  DebugNotificationData,
  DebugNotificationParams,
  NotificationMeta,
  SendNotificationRes,
  TemplateInfo,
  TemplatesState,
  UpdatedTemplate,
  DebugTemplateInfo,
  TemplateFormType,
  TImportResponse,
} from './templates.types';

export const getTemplate = async (id: string): Promise<TemplateInfo> => {
  try {
    const { data } = await getTemplateReq(id);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getTemplates = async (
  params: TCommonParamsWithFilter<FiltersParams>,
  filters: FiltersParams,
): Promise<TemplatesState> => {
  try {
    const { data } = await getTemplatesReq({
      limit: params.limit,
      offset: params.skip,
      ...getSortingParam(params),
      ...filters,
    });
    return {
      result: data?.results,
      total: data?.count,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getFiltersOptions = async (
  brandings: string[],
): Promise<FiltersState> => {
  try {
    const tagsResponse = await getTagsReq();
    return {
      [FilterType.EventTagsName]: tagsResponse.data.map(({ name }) => name),
      [FilterType.Branding]: brandings,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getArchiveTemplates = async (
  params: TCommonParams,
): Promise<TemplatesState> => {
  try {
    const { data } = await getArchiveTemplatesReq({
      limit: params.limit,
      offset: params.skip,
      ...getSortingParam(params),
    });
    return {
      result: data?.results,
      total: data?.count,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getTagsOptions = async (): Promise<ISelectOption[]> => {
  try {
    const { data } = await getTagsReq();
    return data?.map(({ name }) => ({
      label: name,
      value: name,
    }));
  } catch (error: any) {
    throw new Error(error);
  }
};

export const debugNotification = async (
  params: DebugNotificationParams,
  reqData?: DebugNotificationData,
): Promise<DebugTemplateInfo> => {
  try {
    const { data } = await debugNotificationReq(params, reqData);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const sendNotification = async (
  reqData: NotificationMeta['req_json'],
): Promise<SendNotificationRes> => {
  try {
    const { data } = await sendNotificationReq(reqData);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createTemplate = async (
  reqData: TemplateFormType,
): Promise<UpdatedTemplate> => {
  try {
    const { data } = await createTemplateReq(reqData);
    return data;
  } catch (error: any) {
    if (error?.response?.data) {
      throw new Error(parseErrors(error?.response.data));
    } else {
      throw new Error(error);
    }
  }
};

export const importTemplates = async (
  reqData: TemplateFormType,
): Promise<TImportResponse> => {
  try {
    const { data } = await importTemplatesReq(reqData);
    return data;
  } catch (error: any) {
    if (error?.response?.data) {
      throw new Error(parseErrors(error?.response.data));
    } else {
      throw new Error(error);
    }
  }
};

export const updateTemplate = async (
  name: string,
  reqData: TemplateFormType,
): Promise<UpdatedTemplate> => {
  try {
    const { data } = await updateTemplateReq(name, reqData);
    return data;
  } catch (error: any) {
    if (error?.response?.data) {
      throw new Error(parseErrors(error?.response.data));
    } else {
      throw new Error(error);
    }
  }
};

export const exportTemplates = async (
  reqData: Record<string, unknown>[],
): Promise<Blob> => {
  try {
    const { data } = await exportTemplatesReq(reqData);
    return data;
  } catch (error: any) {
    if (error?.response?.data) {
      throw new Error(parseErrors(error?.response.data));
    } else {
      throw new Error(error);
    }
  }
};

export const exportTemplatesTexts = async (reqData: string): Promise<Blob> => {
  try {
    const { data } = await exportTemplatesTextsReq(reqData);
    return data;
  } catch (error: any) {
    if (error?.response?.data) {
      throw new Error(parseErrors(error?.response.data));
    } else {
      throw new Error(error);
    }
  }
};
