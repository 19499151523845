import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Notification, useData } from 'react-ui-kit-exante';

import { EventForm } from '../../components/EventForm/EventForm';
import { Params } from '../../routes/types';
import { EventsService } from '../../services/shaper/events';
import { TEventDetail } from '../../services/shaper/events/events.types';

export const EditEvent: FC = () => {
  const { id } = useParams<Params>();

  const getEvent = useCallback(async () => {
    if (id) {
      try {
        return await EventsService.getEvent(id);
      } catch (error: any) {
        Notification.error(error?.message);
      }
    }
    return null;
  }, [id]);

  const eventData = useData<TEventDetail | null>({
    onFetch: getEvent,
  });

  useEffect(() => {
    eventData.fetchData();
  }, [eventData.fetchData]);

  const { isLoading } = eventData;
  return isLoading ? (
    <Loader isCentered />
  ) : (
    <EventForm
      eventData={eventData.data}
      eventDataFetch={eventData.fetchData}
      id={id}
    />
  );
};
