import { useEffect } from 'react';

import { DefaultThemeProvider } from '../../../theme';
import { Description, Message, Title } from '../Notification.styled';

import { TToastContentProps } from './ToastContent.types';

/**
 * A tricky way to implement close button handling for individual toast
 * At the moment there is no convenient way to deal with it by 'react-hot-toast'
 */
export const TOAST_CLOSE_HANDLERS: { [id: string]: () => void } = {};

/**
 * Helper component for {@link Notification}
 *
 * Renders inside the ToastBar for each Toast instance
 * Adds ability to attach close handler for specific Toast
 *
 * @param title       main text of notification
 * @param description details
 * @param toastId     unique identifier returned by toast generator
 * @param onClose     optional close handler
 */
export const ToastContent = ({
  title,
  description,
  toastId,
  onClose,
}: TToastContentProps) => {
  useEffect(() => {
    // add close handler to global object
    // (will be triggered on click)
    if (onClose) {
      TOAST_CLOSE_HANDLERS[toastId] = onClose;
    }

    return () => {
      // keep the object clean of unused handlers
      delete TOAST_CLOSE_HANDLERS[toastId];
    };
  }, [onClose, toastId]);

  return (
    <DefaultThemeProvider>
      <Message className="NotificationMessage">
        <Title className="NotificationTitle">{title}</Title>
        <Description className="NotificationDescription">
          {description}
        </Description>
      </Message>
    </DefaultThemeProvider>
  );
};
