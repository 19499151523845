import { Counter } from './LabelTab.styled';

interface LabelTabProps {
  label: string;
  numberTab: number;
  activeTab: number;
  counterValue?: number | string | undefined;
}
export const LabelTab = ({
  label,
  counterValue,
  numberTab,
  activeTab,
}: LabelTabProps) => {
  const isCounter: boolean = typeof counterValue === 'undefined';
  return (
    <span>
      {label}{' '}
      {!isCounter && (
        <Counter isActive={activeTab !== numberTab}>
          {typeof counterValue === 'number' ? counterValue : '–'}
        </Counter>
      )}
    </span>
  );
};
