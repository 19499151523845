import { IndeterminateCheckbox } from '../components/IndeterminateCheckbox';
import { IndeterminateCheckboxHead } from '../components/IndeterminateCheckboxHead';

export const addExtraColumn = (hooks) =>
  hooks.visibleColumns.push((allColumns) => [
    {
      id: 'selectionBulk',
      width: 65,
      minWidth: 65,
      hideInMenu: true,
      disableFilters: true,
      Header: (header) => <IndeterminateCheckboxHead header={header} />,
      Cell: ({ row }) => (
        <IndeterminateCheckbox
          {...row.getToggleRowSelectedProps({ title: '' })}
          row={row}
        />
      ),
    },
    ...allColumns,
  ]);
