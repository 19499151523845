import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Deposit = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 8v16a2 2 0 002 2h20a1 1 0 001-1V11a1 1 0 00-1-1H7a2 2 0 01-2-2zm0 0a2 2 0 012-2h17"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5 19.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            fill="currentColor"
          />
          <path
            d="M22.5 19.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            stroke="currentColor"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 7v16a2 2 0 002 2h20a1 1 0 001-1V10a1 1 0 00-1-1H7a2 2 0 01-2-2zm0 0a2 2 0 012-2h17"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5 18.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            fill="currentColor"
          />
          <path
            d="M22.5 18.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            stroke="currentColor"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 7v16a2 2 0 002 2h20a1 1 0 001-1V10a1 1 0 00-1-1H7a2 2 0 01-2-2zm0 0a2 2 0 012-2h17"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5 18.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M5 8v16a2 2 0 002 2h20a1 1 0 001-1V11a1 1 0 00-1-1H7a2 2 0 01-2-2zm0 0a2 2 0 012-2h17"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.25 18a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Deposit;
