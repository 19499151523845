import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const OrderReport = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.477 26.666H6.303a.97.97 0 01-.97-.97V6.304a.97.97 0 01.97-.97h19.394a.97.97 0 01.97.97v13.174a.97.97 0 01-.284.686l-6.22 6.22a.967.967 0 01-.686.283v0zM10 16h12M16 10v12"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.477 26.666H6.303a.97.97 0 01-.97-.97V6.304a.97.97 0 01.97-.97h19.394a.97.97 0 01.97.97v13.174a.97.97 0 01-.284.686l-6.22 6.22a.967.967 0 01-.686.283v0zM10 16h12M16 10v12"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.477 26.666H6.303a.97.97 0 01-.97-.97V6.304a.97.97 0 01.97-.97h19.394a.97.97 0 01.97.97v13.174a.97.97 0 01-.284.686l-6.22 6.22a.967.967 0 01-.686.283v0zM10 16h12M16 10v12"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19.477 26.666H6.303a.97.97 0 01-.97-.97V6.304a.97.97 0 01.97-.97h19.394a.97.97 0 01.97.97v13.174a.97.97 0 01-.284.686l-6.22 6.22a.968.968 0 01-.686.283v0zM10 16h12M16 10v12" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default OrderReport;
