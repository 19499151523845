import { FC, useState } from 'react';
import { IconButton, Select } from 'react-ui-kit-exante';

import {
  StyledTemplateHeaderActions,
  StyledTemplateHeaderInfo,
  StyledTemplateHeaderTitle,
  StyledTemplateHeaderWrapper,
} from './TemplateHeader.styled';
import { TemplateHeaderProps } from './TemplateHeader.types';
import { TemplateInfoBlock } from './components/TemplateInfoBlock/TemplateInfoBlock';

export const TemplateHeader: FC<TemplateHeaderProps> = ({
  templatesList,
  selectedTemplateBranding,
  selectedTemplateId,
  onSelectTemplate,
  onAddTemplate,
  onCopyTemplate,
  onDeleteTemplate,
  onOpenTemplateHistory,
}) => {
  const [isCopyTemplate, setCopyTemplate] = useState(false);

  const isTemplates = !!templatesList.length && !!selectedTemplateId;

  const formTitle = isCopyTemplate ? 'Copy template' : 'Add template';

  const handleCopyTemplate = () => {
    setCopyTemplate(true);
    onCopyTemplate();
  };

  return (
    <StyledTemplateHeaderWrapper columns={2} className="TemplateHeaderWrapper">
      <div className="TemplateHeaderSelect">
        {templatesList.length && selectedTemplateId && !isCopyTemplate ? (
          <Select
            options={templatesList}
            label="Template name"
            onChange={onSelectTemplate}
            value={String(selectedTemplateId)}
            fullWidth
            data-test-id="notifications-module__button--select-template"
          />
        ) : (
          <StyledTemplateHeaderTitle>{formTitle}</StyledTemplateHeaderTitle>
        )}
      </div>
      <StyledTemplateHeaderInfo className="TemplateHeaderInfo">
        {isTemplates && !isCopyTemplate && (
          <TemplateInfoBlock
            label="Brandings"
            value={selectedTemplateBranding}
          />
        )}
        <StyledTemplateHeaderActions className="TemplateHeaderActions">
          {isTemplates && !isCopyTemplate && (
            <>
              <IconButton
                label="Add template"
                iconColor="action"
                iconName="AddIcon"
                iconSize={24}
                onClick={onAddTemplate}
                data-test-id="notifications-module__button--add-template"
              />
              <IconButton
                title="Copy template as new"
                iconColor="secondary"
                iconName="CopyIcon"
                iconSize={24}
                onClick={handleCopyTemplate}
                data-test-id="notifications-module__button--copy-template"
              />
              <IconButton
                title="Delete template"
                iconColor="secondary"
                iconName="DeleteIcon"
                iconSize={24}
                onClick={onDeleteTemplate}
                data-test-id="notifications-module__button--delete-template"
              />
              <IconButton
                title="Template history"
                iconColor="secondary"
                iconSize={24}
                iconName="ActivityIcon"
                onClick={onOpenTemplateHistory}
                data-test-id="notifications-module__button--history"
              />
            </>
          )}
        </StyledTemplateHeaderActions>
      </StyledTemplateHeaderInfo>
    </StyledTemplateHeaderWrapper>
  );
};
