import React from 'react';
import { HeaderGroup } from 'react-table';

import { useTheme } from '../../theme';

import { CellStyled, RowStyled } from './Table.styled';
import { TPropColumnGetter } from './types';

interface ITableFooterProps<Data extends object> {
  footerGroups: Array<HeaderGroup<Data>>;
  getFooterPropsExternal: TPropColumnGetter<Data>;
}

export const TableFooter = <Data extends object>({
  footerGroups,
  getFooterPropsExternal,
}: ITableFooterProps<Data>) => {
  const theme = useTheme();

  return (
    <div role="rowgroup" data-test-id="table__footer" className="TableFooter">
      {footerGroups.map((group) => (
        <RowStyled
          {...group.getFooterGroupProps()}
          key={group.getHeaderGroupProps().key}
        >
          {group.headers.map((column) => {
            return (
              <CellStyled
                {...column.getFooterProps(
                  getFooterPropsExternal(column, theme),
                )}
                key={column.id}
              >
                {column.render('Footer')}
              </CellStyled>
            );
          })}
        </RowStyled>
      ))}
    </div>
  );
};
