import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  ISelectOption,
  Loader,
  Notification,
  useData,
} from 'react-ui-kit-exante';

import { SettingsService } from '../services/shaper/settings';
import { SettingsResponse } from '../services/shaper/settings/settings.types';
import { transformVariantsToSelectOptions } from '../utils/transformVariantsToSelectOptions';

export type TypesVerbose = {
  name: string;
  title: string;
};

type SettingsState = Omit<
  SettingsResponse,
  'can_sync_translations' | 'types_verbose'
> & {
  brandingsOptions: ISelectOption[];
  groupsOptions: ISelectOption[];
  typesOptions: ISelectOption[];
  typesVerbose: TypesVerbose[];
  canSyncTranslations: boolean;
};

const initialState: SettingsState = {
  version: '',
  types: [],
  typesVerbose: [],
  brandingsOptions: [],
  groupsOptions: [],
  typesOptions: [],
  brandings: [],
  groups: [],
  canSyncTranslations: false,
};

export const SettingsContext = createContext<SettingsState>(initialState);

type Props = {
  children: ReactElement;
};

export const SettingsProvider = ({ children }: Props): ReactElement | null => {
  const getSettings = useCallback(async () => {
    try {
      return await SettingsService.getSettings();
    } catch (error: any) {
      Notification.error(error?.message);
    }
    return null;
  }, []);

  const { fetchData, isLoading, data } = useData<SettingsResponse | null>({
    onFetch: getSettings,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const settings = useMemo(() => {
    return data
      ? {
          ...data,
          brandingsOptions: transformVariantsToSelectOptions(data.brandings),
          groupsOptions: transformVariantsToSelectOptions(data.groups),
          typesOptions: transformVariantsToSelectOptions(data.types),
          typesVerbose: data.types_verbose,
          canSyncTranslations: data.can_sync_translations,
        }
      : initialState;
  }, [data]);

  if (isLoading) {
    return <Loader isCentered />;
  }

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};
