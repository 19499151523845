import { uniq } from 'lodash';

import { getColumnKey } from '../../helpers';
import type { IColumn } from '../../types';

export const getKeysByColumns = <Data extends object>(
  columns: IColumn<Data>[],
): string[] => columns.map((column) => getColumnKey(column));

/**
 * Keys of required columns from IColumn[]
 */
export const getRequiredColumnKeys = <Data extends object>(
  columns: IColumn<Data>[],
) =>
  columns.reduce(
    (acc, curr) => (curr.required ? [...acc, getColumnKey(curr)] : acc),
    [],
  );

/**
 * Columns which we should show on first render.
 * It is keys from displayedColumnKeys prop
 * and keys with required attribute inside IColumn
 */
export const getDefaultColumnKeys = <Data extends object>(
  columns: IColumn<Data>[],
  requiredColumnKeys: string[],
  displayedColumnKeys?: string[],
) => {
  if (!displayedColumnKeys) {
    return null;
  }

  const existingColumns = columns.filter((column) =>
    displayedColumnKeys.includes(column.accessor as string),
  );
  const existingColumnsKeys = getKeysByColumns(existingColumns);

  return uniq(existingColumnsKeys.concat(requiredColumnKeys));
};

interface IGetPredefinedColumnKeysArguments<Data extends object> {
  lsColumnsData: string;
  requiredColumnKeys: string[];
  columns: IColumn<Data>[];
  defaultColumnKeys: string[];
}

/**
 * Keys which we should show
 * Higher priority is localStorage (it means user set up his columns)
 * Second one is defaultColumns (which we get from displayedColumns, for example first render)
 * Third – All existing columns keys.
 * @param lsColumnsData
 * @param requiredColumnKeys
 * @param columns
 * @param defaultColumnKeys
 */
export const getPredefinedColumnKeys = <Data extends object>({
  lsColumnsData,
  requiredColumnKeys,
  columns,
  defaultColumnKeys,
}: IGetPredefinedColumnKeysArguments<Data>) => {
  if (lsColumnsData) {
    const keys = JSON.parse(lsColumnsData);

    return uniq<(typeof requiredColumnKeys)[number]>(
      keys.concat(requiredColumnKeys),
    );
  }

  if (defaultColumnKeys) {
    return defaultColumnKeys;
  }

  return getKeysByColumns(columns);
};

/**
 * Keys which we should send to react-table to hide columns
 */
export const getHiddenColumns = <Data extends object>(
  columns: IColumn<Data>[],
  visibleColumnKeys: string[],
) =>
  columns.reduce<string[]>((result, column) => {
    const key = getColumnKey(column);

    if (key && !visibleColumnKeys.includes(key)) {
      result.push(key);
    }

    return result;
  }, []);
