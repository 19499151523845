import { SortingRule } from 'react-table';

import { IColumn, Sorting } from '../types';

export const prepareFields = <Data extends object>(
  fields: string[],
  columns: IColumn<Data>[],
) =>
  fields.map((fieldName) => {
    const column = columns.find(({ accessor }) => accessor === fieldName);
    const header =
      typeof column?.Header === 'string' ? column?.Header : fieldName;
    return {
      value: fieldName,
      label: header,
    };
  });

export const getFieldsListFromColumns = <Data extends object>(
  columns: IColumn<Data>[],
  visibleColumnKeys: string[],
) => {
  const fields = columns.reduce<string[]>(
    (acc, { accessor = '', printable = true }) => {
      const newField = `${String(accessor)}`;
      if (printable && visibleColumnKeys.includes(newField)) {
        acc.push(newField);
      }
      return acc;
    },
    [],
  );

  return fields;
};

export const initDownload = (url: string, title: string) => {
  const link = document.createElement('a');
  link.download = `${title}.csv`;
  link.href = url;
  link.click();
};

export const prepareSorting = <Data extends object>(
  sorting: SortingRule<Data>[],
) => {
  if (sorting[0] && Object.keys(sorting[0]).length) {
    return {
      order: sorting[0].desc ? 'desc' : 'asc',
      order_by: [sorting[0].id],
    } as Sorting;
  }

  return {} as Sorting;
};

export const callFnSafely = <T extends (...args: unknown[]) => unknown>(
  fn: T,
  ...args: unknown[]
) => {
  if (fn && typeof fn === 'function') {
    fn(...args);
    return true;
  }

  return false;
};
