import { DateTimePickerSlotsComponentsProps } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import { FC, useCallback, useState } from 'react';

import { useTranslator } from '../../../../contexts/TranslationContext';
import { getBrowserDateFormat } from '../../../../formatters';
import { getBrowserLocale } from '../../../../helpers/getBrowserLocale';
import { InputDatePicker } from '../../../Inputs/Date/Branded';
import { DatePickerStyled } from '../Filters.styled';
import { TDateValue } from '../types';

const componentsPropsWithClearable: Partial<DateTimePickerSlotsComponentsProps> =
  {
    actionBar: { actions: ['clear'] },
  };

interface IProps {
  onChange: (values: TDateValue) => void;
  value: TDateValue;
  useLocale?: boolean;
  useBrandedPicker?: boolean;
}

export const DateInput: FC<IProps> = ({
  onChange,
  value = null,
  useLocale = false,
  useBrandedPicker = false,
}) => {
  const { locale } = useTranslator();

  const [localValue, setLocalValue] = useState<TDateValue | Date>(value);
  const handleChangeDate = useCallback(
    (date: Date) => {
      if (!date) {
        onChange(null);
      } else if (!Number.isNaN(date.getTime())) {
        setLocalValue(date);
        onChange(date);
      }
    },
    [localValue, onChange, useLocale],
  );

  const notBrandedDateFormat = getBrowserDateFormat(
    false,
    useLocale,
    getBrowserLocale,
  );

  const selectedDateObj =
    typeof localValue === 'string' ? new Date(localValue) : localValue;

  return useBrandedPicker ? (
    <InputDatePicker
      selected={selectedDateObj}
      onChange={handleChangeDate}
      locale={locale}
      showClearButton
      inputProps={{
        size: 'small',
        'data-test-id': 'filters__input--date',
      }}
    />
  ) : (
    <DatePickerStyled
      inputFormat={notBrandedDateFormat}
      value={localValue || null}
      onChange={handleChangeDate}
      size="small"
      locale={locale}
      componentsProps={componentsPropsWithClearable}
      controlProps={{
        inputProps: {
          'data-test-id': 'filters__input--date',
        },
      }}
    />
  );
};
