import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const PeriodicReports = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1 1 0 01-.293.707l-6.415 6.415a1 1 0 01-.706.292z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.91 19.999H20v6.91M22 3v4M10 3v4M5 11h22"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1 1 0 01-.293.707l-6.415 6.415a1 1 0 01-.706.292z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.886 16.114L16 20.227l4.114-4.113M22 3v4M10 3v4M5 11h22"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1 1 0 01-.293.707l-6.415 6.415a1 1 0 01-.706.292z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.886 16.114L16 20.227l4.114-4.113M22 3v4M10 3v4M5 11h22"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1 1 0 01-.293.707l-6.415 6.415a1 1 0 01-.706.292z" />
            <path d="M26.91 19.999H20v6.91M22 3v4M10 3v4M5 11h22" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default PeriodicReports;
