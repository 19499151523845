import { CancelToken } from 'axios';

import { ResponseWithStatus } from '../../api/Api.types';
import { getDefaultReqParams, shaperApi } from '../consts';

import {
  FETCH_TRANSLATIONS,
  PULL_NEW_TRANSLATIONS,
  PUSH_KEYS,
} from './translations.consts';
import { TranslationFullInfo, TranslationParams } from './translations.types';

export function getTranslationsByRevisionReq(
  params: TranslationParams,
  cancelToken: CancelToken,
) {
  return shaperApi.get<TranslationFullInfo>({
    endPoint: FETCH_TRANSLATIONS,
    params,
    ...getDefaultReqParams(cancelToken),
  });
}

export function pushKeysReq() {
  return shaperApi.post<ResponseWithStatus>({
    endPoint: PUSH_KEYS,
    ...getDefaultReqParams(),
  });
}

export function pullNewReq() {
  return shaperApi.post<TranslationFullInfo>({
    endPoint: PULL_NEW_TRANSLATIONS,
    ...getDefaultReqParams(),
  });
}
