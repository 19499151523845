import { PopperProps } from '@mui/material/Popper';

import SelectArrowIcon from '../../../Icons/components/ArrowDown';
import { DefaultThemeProvider } from '../../../theme/DefaultThemeProvider';
import { Skeleton } from '../../Skeleton';
import { Input } from '../Input/Input';

import { AutoCompleteStyled, StyledPopper } from './Autocomplete.styled';
import { IAutocompleteProps } from './types';

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  isMultiple,
  options,
  onChange,
  dataTestId,
  value,
  placeholder,
  size = 'medium',
  renderInput,
  controlProps,
  fullWidth,
  showSkeleton,
  floating = true,
  ...props
}: IAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const defineStyledPopper = (popperProps: PopperProps) => (
    <StyledPopper size={size} {...popperProps} />
  );

  return (
    <DefaultThemeProvider>
      {showSkeleton ? (
        <Skeleton
          width={fullWidth ? '100%' : 320}
          height={size === 'medium' ? 48 : 32}
        />
      ) : (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AutoCompleteStyled
          forcePopupIcon
          disablePortal
          floating={floating}
          multiple={isMultiple}
          options={options}
          sx={{
            width: fullWidth ? '100%' : 320,
          }}
          value={value}
          size={size}
          ChipProps={{
            size,
          }}
          popupIcon={<SelectArrowIcon />}
          renderInput={(params) => {
            if (renderInput) {
              return renderInput(params);
            }

            return (
              <Input
                placeholder={placeholder}
                {...controlProps}
                {...params}
                InputProps={params.InputProps}
              />
            );
          }}
          onChange={onChange}
          PopperComponent={defineStyledPopper}
          data-test-id={dataTestId}
          {...props}
        />
      )}
    </DefaultThemeProvider>
  );
};
