import { styled } from 'react-ui-kit-exante';

export const StyledInfoFieldWrapper = styled('dl')`
  padding: 0;
  margin: 0;

  color: ${({ theme }) => theme.font.header};
`;

export const StyledInfoFieldTitle = styled('dt')`
  padding: 0;
  margin: 0 0 16px;

  color: ${({ theme }) => theme.color.typo.promo};

  font-size: 13px;
  font-weight: 300;
`;

export const StyledInfoFieldData = styled('dd')`
  padding: 0;
  margin: 0;

  color: ${({ theme }) => theme.color.typo.primary};

  font-size: 15px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
