import { FC, useContext, useMemo } from 'react';

import { SettingsContext } from '../../../contexts/SettingsContext';
import { FormMultiSelectContainer } from '../../Form/FormMultiSelectContainer/FormMultiSelectContainer';

import { TAddBrandingProps } from './AddBranding.types';

export const AddBranding: FC<TAddBrandingProps> = ({
  id,
  brandingsUsed,
  isEditable,
}) => {
  const { brandingsOptions, brandings } = useContext(SettingsContext);

  const options = useMemo(() => {
    if (!id) {
      return brandings.map((item) => ({
        label: item,
        value: item,
      }));
    }
    return brandingsOptions.filter(
      (option) => !brandingsUsed.includes(String(option.value)),
    );
  }, [brandingsUsed]);

  return (
    <FormMultiSelectContainer
      label="Brandings"
      name="template.brandings"
      options={options || []}
      disabled={!isEditable}
    />
  );
};
