import { useNavigate } from 'react-router-dom';
import { Button, IColumn } from 'react-ui-kit-exante';

import { EVENTS_PATH, MAILINGS_PATH } from '../../routes';
import { Mailing } from '../../services/shaper/mailings/mailings.types';
import { CellValue } from '../../types/table';

import { getRecipient } from './MailingLists.utils';

export const hiddenColumns = ['Id'];

export const columns: IColumn<Mailing>[] = [
  { Header: 'Id', accessor: 'id', disableFilters: true },
  {
    Header: 'Name',
    accessor: 'name',
    required: true,
    disableFilters: true,
    Cell: ({ row: { values } }: CellValue<Mailing>) => {
      const navigate = useNavigate();
      return (
        <Button
          color="transparent"
          textColor="action"
          onClick={() => navigate(`${MAILINGS_PATH}/${values.id}`)}
        >
          {values.name}
        </Button>
      );
    },
  },
  {
    Header: 'Brandings',
    accessor: 'brandings',
    disableFilters: true,
  },
  {
    Header: 'To',
    accessor: 'recipients_to',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row: { values } }: CellValue<Mailing>) => {
      return getRecipient(values.recipients_to);
    },
  },
  {
    Header: 'Cc',
    accessor: 'recipients_cc',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row: { values } }: CellValue<Mailing>) => {
      return getRecipient(values.recipients_cc);
    },
  },
  {
    Header: 'Bcc',
    accessor: 'recipients_bcc',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row: { values } }: CellValue<Mailing>) => {
      return getRecipient(values.recipients_bcc);
    },
  },
  {
    Header: 'Templates',
    accessor: 'templates',
    disableFilters: true,
    Cell: ({ row: { values } }: CellValue<Mailing>) => {
      const navigate = useNavigate();
      if (!values?.templates?.length) {
        return '–';
      }
      return values?.templates?.map((template) => {
        if (!template?.event_id) {
          return template.name;
        }
        return (
          <div key={template.id} title={template.name}>
            <Button
              color="transparent"
              textColor="action"
              onClick={() =>
                navigate(
                  `${EVENTS_PATH}/${template.event_id}?templateId=${template.name}`,
                )
              }
            >
              {template.name}
            </Button>
          </div>
        );
      });
    },
  },
];
