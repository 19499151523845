import { DATE_FORMAT } from '../../../consts';
import { formatters } from '../../../helpers/formatters';
import type { DefaultTableCellProps } from '../TableBody/types';

export const FormattedTableCell = <Data extends object>({
  value,
  column,
}: DefaultTableCellProps<Data>) => {
  const { formatting, type } = column;
  let useLocale = false;
  let dateFormat = DATE_FORMAT;

  const isDateColumn =
    type === 'dateInput' || type === 'dateRange' || type === 'dateTimeRange';

  if (isDateColumn) {
    ({ useLocale, dateFormat } = column);
  }

  const format = formatters[formatting];

  if (isDateColumn) {
    return <div>{format(value, dateFormat, useLocale)}</div>;
  }

  return <div>{format(value)}</div>;
};
