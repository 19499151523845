import { FC, useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes, Router } from 'react-router-dom';
import { NotificationProvider } from 'react-ui-kit-exante';

import { PageProvider } from 'contexts/PagesContext';

import { SettingsProvider } from '../../contexts/SettingsContext';
import { CreateEvent } from '../../pages/CreateEvent';
import { CreateMailings } from '../../pages/CreateMailings';
import { EditEvent } from '../../pages/EditEvent';
import { EditMailings } from '../../pages/EditMailings/EditMailings';
import { Events } from '../../pages/Events';
import { MailingLists } from '../../pages/MailingLists';
import { Templates } from '../../pages/Templates';
import { TemplatesArchive } from '../../pages/TemplatesArchive';
import { Translations } from '../../pages/Translations';
import {
  ALL_TRANSLATIONS_PATH,
  MAILINGS_PATH,
  MAILINGS_EDIT_PATH,
  MAILINGS_CREATE_PATH,
  TEMPLATES_ARCHIVE_PATH,
  EVENTS_PATH,
  EVENT_EDIT_PATH,
  EVENT_CREATE_PATH,
  TEMPLATES_PATH,
} from '../../routes';
import { getBaseName } from '../../utils/getBaseName';
import { AppVersion } from '../AppVersion/AppVersion';
import { ErrorFallback } from '../ErrorFallback';
import { Header } from '../Header/Header';

export const Application: FC = () => {
  const history = window.runUIhistoryContainer;
  const [navigator, setNavigator] = useState({
    action: history?.action,
    location: history?.location,
  });

  useLayoutEffect(() => {
    if (history) {
      history.listen(() => {
        return setNavigator({
          action: history.action,
          location: history.location,
        });
      });
    }
  }, [history]);

  const AppRoutes = (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Routes>
        <Route path={EVENTS_PATH} element={<Events />} />
        <Route path={TEMPLATES_PATH} element={<Templates />} />
        <Route path={TEMPLATES_ARCHIVE_PATH} element={<TemplatesArchive />} />
        <Route path={ALL_TRANSLATIONS_PATH} element={<Translations />} />
        <Route path={MAILINGS_PATH} element={<MailingLists />} />
        <Route path={MAILINGS_EDIT_PATH} element={<EditMailings />} />
        <Route path={MAILINGS_CREATE_PATH} element={<CreateMailings />} />
        <Route path={EVENT_EDIT_PATH} element={<EditEvent />} />
        <Route path={EVENT_CREATE_PATH} element={<CreateEvent />} />
      </Routes>
    </ErrorBoundary>
  );
  return (
    <SettingsProvider>
      <PageProvider>
        <>
          <NotificationProvider />
          {window.runUIhistoryContainer ? (
            <Router
              location={navigator.location}
              navigationType={navigator.action}
              navigator={history}
            >
              {AppRoutes}
            </Router>
          ) : (
            <BrowserRouter basename={getBaseName()}>
              <Header />
              {AppRoutes}
              <AppVersion />
            </BrowserRouter>
          )}
        </>
      </PageProvider>
    </SettingsProvider>
  );
};
