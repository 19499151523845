import { FC } from 'react';

import {
  StyledTemplateInfoBlock,
  StyledTemplateInfoBlockLabel,
  StyledTemplateInfoBlockValue,
} from './TemplateInfoBlock.styled';
import { TTemplateInfoBlockProps } from './TemplateInfoBlock.types';

export const TemplateInfoBlock: FC<TTemplateInfoBlockProps> = ({
  label,
  value,
}) => {
  return (
    <StyledTemplateInfoBlock>
      <StyledTemplateInfoBlockLabel>{label}</StyledTemplateInfoBlockLabel>
      <StyledTemplateInfoBlockValue>{value}</StyledTemplateInfoBlockValue>
    </StyledTemplateInfoBlock>
  );
};
