import { styled } from 'react-ui-kit-exante';

export const StyledTemplateCheckboxes = styled('div')`
  grid-column: 1 / -1;
  display: flex;
  gap: 24px;
`;

export const StyledTemplateCheckboxLabel = styled('span')`
  display: flex;
  gap: 8px;
`;
