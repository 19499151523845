import { useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { Input } from 'react-ui-kit-exante';

import { IFormInputContainerProps } from './FormInputContainer.types';

export const FormInputContainer = ({
  customEvents = {},
  disabled = false,
  readOnly = false,
  fullWidth = true,
  label = '',
  name,
  type,
  multiline = false,
  message,
}: IFormInputContainerProps) => {
  const formContext = useFormContext();
  const {
    field: { onChange, onBlur: onBlurReactHookForm, value },
    fieldState: { error },
  } = useController({
    control: formContext.control,
    name,
  });

  formContext.register(name, { disabled });

  const { onBlur: onBlurCustomEvents } = customEvents;

  const blurHandler = useCallback(() => {
    if (onBlurCustomEvents) {
      onBlurCustomEvents(formContext);
    }

    onBlurReactHookForm();
  }, [formContext, onBlurCustomEvents, onBlurReactHookForm]);

  return (
    <Input
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      label={label}
      message={message || error?.message}
      name={name}
      onBlur={blurHandler}
      onChange={onChange}
      type={type}
      value={value === null ? '' : value}
      readOnly={readOnly}
      multiline={multiline}
    />
  );
};
