import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Profile = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 19.333a5 5 0 100-10 5 5 0 000 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 5.333H5a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1v-18a1 1 0 00-1-1zM7.512 25.333a9 9 0 0116.975 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 18a5 5 0 100-10 5 5 0 000 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 5.333H5a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1v-18a1 1 0 00-1-1zM7.512 25.333a9 9 0 0116.975 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 18a5 5 0 100-10 5 5 0 000 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 5.333H5a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1v-18a1 1 0 00-1-1zM7.512 25.333a9 9 0 0116.975 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 19.333a5 5 0 100-10 5 5 0 000 10z" />
            <path d="M27 5.333H5a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1v-18a1 1 0 00-1-1zM7.512 25.333a9 9 0 0116.975 0" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Profile;
