import { getDefaultReqParams, shaperApi } from '../consts';
import { DefaultGetParams } from '../types';

import { FETCH_EVENTS } from './events.consts';
import {
  EventFormType,
  EventsListResponse,
  TEventDetail,
} from './events.types';

export function getEventsReq(params: DefaultGetParams) {
  return shaperApi.get<EventsListResponse>({
    endPoint: `${FETCH_EVENTS}`,
    params,
    ...getDefaultReqParams(),
  });
}

export function deleteEventReq(id: string) {
  return shaperApi.delete<string>({
    endPoint: `${FETCH_EVENTS}${id}/`,
    ...getDefaultReqParams(),
  });
}

export function getEventReq(id: string) {
  return shaperApi.get<TEventDetail>({
    endPoint: `${FETCH_EVENTS}${id}/`,
    ...getDefaultReqParams(),
  });
}

export function updateEventReq(id: string, data: EventFormType) {
  return shaperApi.put<TEventDetail>({
    endPoint: `${FETCH_EVENTS}${id}/`,
    data,
    ...getDefaultReqParams(),
  });
}

export function createEventReq(data: EventFormType) {
  return shaperApi.post<TEventDetail>({
    endPoint: `${FETCH_EVENTS}`,
    data,
    ...getDefaultReqParams(),
  });
}
