import { getSettingsReq } from './settings.requests';
import { SettingsResponse } from './settings.types';

export const getSettings = async (): Promise<SettingsResponse> => {
  try {
    const { data } = await getSettingsReq();
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
