import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { Application } from '../components/Application';
import { ComposedThemeProvider } from '../theme/ComposedThemeProvider';

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);

  root.render(
    <StrictMode>
      <ComposedThemeProvider>
        <Application />
      </ComposedThemeProvider>
    </StrictMode>,
  );
};
