import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const CircleWavyQuestion = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.75 22.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M16 18v-1a3.5 3.5 0 10-3.5-3.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x={0.75}
            y={0.75}
            width={30.5}
            height={30.5}
            rx={15.25}
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.75 22.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M16 18v-1a3.5 3.5 0 10-3.5-3.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x={0.75}
            y={0.75}
            width={30.5}
            height={30.5}
            rx={15.25}
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 24a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            fill="currentColor"
          />
          <path
            d="M16 18v-1a3.5 3.5 0 10-3.5-3.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x={0.75}
            y={0.75}
            width={30.5}
            height={30.5}
            rx={15.25}
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M16.75 22.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              fill="currentColor"
            />
            <path
              d="M16 18v-1a3.5 3.5 0 10-3.5-3.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect x={0.75} y={0.75} width={30.5} height={30.5} rx={15.25} />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default CircleWavyQuestion;
