import { getDefaultReqParams, shaperApi } from '../consts';
import { DefaultGetParams } from '../types';

import {
  DEBUG_NOTIFICATION,
  FETCH_TEMPLATES,
  SEND_NOTIFICATION,
  FETCH_ARCHIVE_TEMPLATES,
  DOWNLOAD_TEMPLATES,
  DOWNLOAD_TEMPLATES_TEXTS,
} from './templates.consts';
import {
  DebugNotificationData,
  DebugTemplateInfo,
  DebugNotificationParams,
  NotificationMeta,
  SendNotificationRes,
  TemplateFormType,
  TemplateListResponse,
  TemplateResponse,
  UpdatedTemplate,
  TImportResponse,
} from './templates.types';

export function getTemplateReq(name: string) {
  return shaperApi.get<TemplateResponse>({
    endPoint: `${FETCH_TEMPLATES}${name}/`,
    ...getDefaultReqParams(),
  });
}

export function deleteTemplateReq(name: string) {
  return shaperApi.delete<string>({
    endPoint: `${FETCH_TEMPLATES}${name}/`,
    ...getDefaultReqParams(),
  });
}

export function getArchiveTemplatesReq(params?: DefaultGetParams) {
  return shaperApi.get<TemplateListResponse>({
    endPoint: `${FETCH_ARCHIVE_TEMPLATES}`,
    params,
    ...getDefaultReqParams(),
  });
}

export function getTemplatesReq(params?: DefaultGetParams) {
  return shaperApi.get<TemplateListResponse>({
    endPoint: `${FETCH_TEMPLATES}`,
    params,
    ...getDefaultReqParams(),
  });
}

export function unarchiveTemplateReq(name: string) {
  return shaperApi.post<TemplateResponse>({
    endPoint: `${FETCH_ARCHIVE_TEMPLATES}${name}/unarchive/`,
    ...getDefaultReqParams(),
  });
}

export function debugNotificationReq(
  params: DebugNotificationParams,
  data?: DebugNotificationData,
) {
  return shaperApi.post<DebugTemplateInfo>({
    endPoint: `${DEBUG_NOTIFICATION}`,
    params,
    data,
    ...getDefaultReqParams(),
  });
}

export function sendNotificationReq(data: NotificationMeta['req_json']) {
  return shaperApi.post<SendNotificationRes>({
    endPoint: `${SEND_NOTIFICATION}`,
    data,
    ...getDefaultReqParams(),
  });
}

export function createTemplateReq(data: TemplateFormType) {
  return shaperApi.post<UpdatedTemplate>({
    endPoint: `${FETCH_TEMPLATES}`,
    data,
    ...getDefaultReqParams(),
  });
}

export function updateTemplateReq(name: string, data: TemplateFormType) {
  return shaperApi.put<UpdatedTemplate>({
    endPoint: `${FETCH_TEMPLATES}${name}/`,
    data,
    ...getDefaultReqParams(),
  });
}

export function importTemplatesReq(data: Record<string, unknown>) {
  return shaperApi.put<TImportResponse>({
    endPoint: `${FETCH_TEMPLATES}import/`,
    data,
    ...getDefaultReqParams(),
  });
}

export function exportTemplatesReq(names: Record<string, unknown>[]) {
  return shaperApi.get<Blob>({
    endPoint: `${DOWNLOAD_TEMPLATES}?name__in=${names}`,
    responseType: 'blob',
    ...getDefaultReqParams(),
  });
}

export function exportTemplatesTextsReq(params: string) {
  return shaperApi.get<Blob>({
    endPoint: `${DOWNLOAD_TEMPLATES_TEXTS}?${params}`,
    responseType: 'blob',
    ...getDefaultReqParams(),
  });
}
