import { getDefaultReqParams, shaperApi } from '../consts';
import { DefaultGetParams } from '../types';

import {
  FETCH_MAILING_LISTS,
  FETCH_MAILING_LISTS_ALL,
} from './mailings.consts';
import {
  MailingListsResponse,
  Mailing,
  MailingFormType,
  MailingListsAllResponse,
} from './mailings.types';

export function getMailingListsReq(params?: DefaultGetParams) {
  return shaperApi.get<MailingListsResponse>({
    params,
    endPoint: FETCH_MAILING_LISTS,
    ...getDefaultReqParams(),
  });
}

export function getMailingListsAllReq(params?: DefaultGetParams) {
  return shaperApi.get<MailingListsAllResponse>({
    params,
    endPoint: FETCH_MAILING_LISTS_ALL,
    ...getDefaultReqParams(),
  });
}

export function deleteMailingListReq(id: string) {
  return shaperApi.delete<string>({
    endPoint: `${FETCH_MAILING_LISTS}${id}/`,
    ...getDefaultReqParams(),
  });
}

export function getMailingReq(id: string) {
  return shaperApi.get<Mailing>({
    endPoint: `${FETCH_MAILING_LISTS}${id}/`,
    ...getDefaultReqParams(),
  });
}

export function createMailingReq(data: MailingFormType) {
  return shaperApi.post<Mailing>({
    endPoint: FETCH_MAILING_LISTS,
    data,
    ...getDefaultReqParams(),
  });
}

export function updateMailingReq(id: string, data: MailingFormType) {
  return shaperApi.put<Mailing>({
    endPoint: `${FETCH_MAILING_LISTS}${id}/`,
    data,
    ...getDefaultReqParams(),
  });
}
