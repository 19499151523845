import { styled } from 'react-ui-kit-exante';

export const StyledFileInput = styled('label')(({ theme }) => ({
  color: theme?.color?.typo?.secondary,
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  height: 24,
  cursor: 'pointer',
  fontSize: 13,
  lineHeight: 1.5,

  ':hover': {
    color: theme?.color?.typo?.promo,
  },
}));
