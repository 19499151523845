import { styled } from 'react-ui-kit-exante';

export const StyledTemplatesActions = styled('div')`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    gap: 24px;
  }
`;
