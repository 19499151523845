import { FC, useContext, useLayoutEffect, useState } from 'react';

import { SettingsContext } from '../../contexts/SettingsContext';
import { getUiVersion } from '../../services/application';

import { AppVersionStyled } from './AppVersion.styled';

export const AppVersion: FC = () => {
  const { version } = useContext(SettingsContext);

  const [clientVersion, setClientVersion] = useState<string | null>(null);
  useLayoutEffect(() => {
    if (!window.runUIhistoryContainer) {
      setClientVersion(getUiVersion());
    }
  }, []);
  return version ? (
    <AppVersionStyled>
      Version {clientVersion} / {version}
    </AppVersionStyled>
  ) : null;
};
