import { getDefaultReqParams, shaperApi } from '../consts';

import { FETCH_TAGS_LIST } from './tags.consts';
import { TagsResponse } from './tags.types';

export function getTagsReq() {
  return shaperApi.get<TagsResponse>({
    endPoint: `${FETCH_TAGS_LIST}`,
    ...getDefaultReqParams(),
  });
}
