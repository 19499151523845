import { useCallback, useContext, useEffect } from 'react';
import { ISelectOption, Notification, useData } from 'react-ui-kit-exante';

import { SettingsContext } from '../../../contexts/SettingsContext';
import { TemplatesService } from '../../../services/shaper/templates';
import { FormInputContainer } from '../../Form/FormInputContainer/FormInputContainer';
import { FormMultiSelectContainer } from '../../Form/FormMultiSelectContainer/FormMultiSelectContainer';
import { FormSelectContainer } from '../../Form/FormSelectContainer/FormSelectContainer';

import {
  StyledEventInfoScript,
  StyledEventInfoWrapper,
} from './EventInfo.styled';

export type EventFormProps = {
  isEventEditable: boolean;
};

export const EventInfo = ({ isEventEditable }: EventFormProps) => {
  const { groupsOptions } = useContext(SettingsContext);

  const getTags = useCallback(async () => {
    try {
      return await TemplatesService.getTagsOptions();
    } catch (error: any) {
      Notification.error(error?.message);
    }
    return [];
  }, []);

  const tagsData = useData<ISelectOption[]>({
    onFetch: getTags,
  });

  useEffect(() => {
    tagsData.fetchData();
  }, [tagsData.fetchData]);

  return (
    <StyledEventInfoWrapper>
      <FormInputContainer
        label="Event"
        name="event.name"
        type="text"
        readOnly={!isEventEditable}
      />
      <FormSelectContainer
        label="Group"
        name="event.group"
        options={groupsOptions}
        readOnly={!isEventEditable}
      />
      <FormInputContainer
        label="Description"
        name="event.description"
        type="text"
        readOnly={!isEventEditable}
      />
      <FormMultiSelectContainer
        label="Add tag"
        name="event.tags"
        options={tagsData.data || []}
        disabled={tagsData.isLoading || !isEventEditable}
      />
      <StyledEventInfoScript>
        <FormInputContainer
          label="Script"
          name="event.script"
          type="text"
          readOnly={!isEventEditable}
          multiline
        />
      </StyledEventInfoScript>
    </StyledEventInfoWrapper>
  );
};
