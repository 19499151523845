import {
  MAILINGS_PATH,
  ALL_TRANSLATIONS_PATH,
  TEMPLATES_ARCHIVE_PATH,
  EVENTS_PATH,
  TEMPLATES_PATH,
} from '../../routes';

export const NAV_LINKS = [
  {
    to: EVENTS_PATH,
    label: 'Events',
  },
  {
    to: TEMPLATES_PATH,
    label: 'Templates',
  },
  {
    to: TEMPLATES_ARCHIVE_PATH,
    label: 'Archive',
  },
  {
    to: MAILINGS_PATH,
    label: 'Mailing Lists',
  },
  {
    to: ALL_TRANSLATIONS_PATH,
    label: 'Translations',
  },
];
