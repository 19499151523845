import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Notification, useData } from 'react-ui-kit-exante';
import { useDebounce } from 'use-debounce';

import { ChannelType, FORM_DELAY, Locales } from '../../../../constants/common';
import { TEventForm } from '../../../../services/shaper/events/events.types';
import { TemplatesService } from '../../../../services/shaper/templates';
import {
  DebugNotificationData,
  DebugNotificationParams,
  DebugTemplateInfo,
} from '../../../../services/shaper/templates/templates.types';
import { FormInputContainer } from '../../../Form/FormInputContainer/FormInputContainer';
import { TranslationInfo } from '../../../TranslationInfo';
import { AddBranding } from '../../AddBranding';
import { TemplateCheckboxes } from '../../TemplateCheckboxes';
import { Editor } from '../Editor';

import {
  StyledPhoneEditorFields,
  StyledPhoneEditorWrapper,
  StyledPhoneTemplateSettings,
  StyledPhoneWrapper,
} from './Phone.styled';
import { PhoneProps } from './Phone.types';
import { PhoneViewer } from './PhoneViewer';

export const Phone: FC<PhoneProps> = ({
  translations,
  trans,
  disabled,
  brandingsUsed = [],
  isEditable = true,
  type = '',
}) => {
  const [locale, setLocale] = useState(Locales.en);
  const { getValues } = useFormContext<TEventForm>();

  const onDebug = useCallback(async () => {
    try {
      const params: DebugNotificationParams = {
        debug: true,
        locale,
        template_name: getValues('template.name'),
        event: getValues('template.event_name'),
        to: getValues('template.sendTo'),
      };

      const reqData: DebugNotificationData = {
        content: getValues('template.content'),
        subject: getValues('template.subject'),
        action: getValues('template.action'),
      };

      return await TemplatesService.debugNotification(params, reqData);
    } catch (error: any) {
      Notification.error(error?.message);
    }
    return null;
  }, [locale]);

  const debugData = useData<DebugTemplateInfo | null>({
    onFetch: onDebug,
    loading: true,
  });

  const onChangeLocale = (lang: Locales) => {
    setLocale(lang);
  };

  const error = debugData.data?.error
    ? {
        lineno: debugData.data?.lineno || 0,
        error: debugData.data?.error,
      }
    : null;

  const isChanged = useWatch({
    name: ['template.content', 'template.subject', 'template.action'],
  });
  const [value] = useDebounce(isChanged, FORM_DELAY);

  useEffect(() => {
    debugData.fetchData();
  }, [value, locale]);

  return (
    <>
      <StyledPhoneWrapper className="PhoneWrapper">
        <StyledPhoneEditorWrapper className="PhoneEditorWrapper">
          <StyledPhoneEditorFields className="PhoneEditorFields">
            <FormInputContainer
              label="Notification title"
              name="template.subject"
              type="text"
              disabled={disabled}
              readOnly={!isEditable}
            />
            <FormInputContainer
              label="Action"
              name="template.action"
              type="text"
              disabled={disabled}
              readOnly={!isEditable}
            />
          </StyledPhoneEditorFields>
          <Editor
            name="template.content"
            error={error}
            type={ChannelType.Phone}
          />
        </StyledPhoneEditorWrapper>

        <PhoneViewer isLoading={debugData.isLoading} info={debugData.data} />
      </StyledPhoneWrapper>
      {translations && (
        <TranslationInfo
          translations={translations}
          onClickHandler={onChangeLocale}
          selectedLocale={locale}
          disabled={debugData.isLoading}
          translationUuid={trans?.uuid}
        />
      )}
      <StyledPhoneTemplateSettings>
        <AddBranding brandingsUsed={brandingsUsed} isEditable={isEditable} />
      </StyledPhoneTemplateSettings>
      <TemplateCheckboxes type={type} isEditable={isEditable} />
    </>
  );
};
