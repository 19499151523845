import { FC } from 'react';

import {
  StyledInfoFieldData,
  StyledInfoFieldTitle,
  StyledInfoFieldWrapper,
} from './InfoField.styled';
import { InfoFieldProps } from './StyledTitle.types';

export const InfoField: FC<InfoFieldProps> = ({ title, data }) => {
  return (
    <StyledInfoFieldWrapper className="InfoFieldWrapper">
      <StyledInfoFieldTitle className="InfoFieldTitle">
        {title}
      </StyledInfoFieldTitle>
      <StyledInfoFieldData title={data} className="InfoFieldData">
        {data}
      </StyledInfoFieldData>
    </StyledInfoFieldWrapper>
  );
};
