import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-ui-kit-exante';

import { Locales } from '../../constants/common';
import { ALL_TRANSLATIONS_PATH } from '../../routes';

import { Flags } from './TranslationInfo.helpers';
import {
  StyledTranslationHeader,
  StyledIconFlag,
  StyledTranslationCountry,
  StyledTranslationWrapper,
  StyledTranslations,
  StyledTranslationTitle,
  StyledTranslation,
} from './TranslationInfo.styled';
import { TranslationInfoProps } from './TranslationInfo.types';

export const TranslationInfo: FC<TranslationInfoProps> = ({
  translations,
  onClickHandler,
  selectedLocale,
  disabled,
  translationUuid,
}) => {
  const navigate = useNavigate();

  const goToAllTranslation = () => {
    navigate(ALL_TRANSLATIONS_PATH);
  };

  // TODO delete this code after move flag icons on server https://jira.exante.eu/browse/NS-100
  const getFlag = (locale: Locales) => {
    const Flag = Flags[locale];
    return Flag ? <Flag /> : '';
  };

  const onLocaleClick = (locale: Locales) => {
    if (onClickHandler && !disabled) {
      onClickHandler(locale);
    }
  };

  return (
    <StyledTranslationWrapper className="TranslationWrapper">
      <StyledTranslationHeader className="TranslationHeader">
        <StyledTranslationTitle className="TranslationTitle">
          Translation
        </StyledTranslationTitle>
        <Button
          onClick={goToAllTranslation}
          disabled={!translationUuid}
          className="StyledTranslationShowAll"
          color="transparent"
        >
          All translations
        </Button>
      </StyledTranslationHeader>
      <StyledTranslations className="Translations">
        {translations?.map(({ locale, percents_color, percents }) => {
          return (
            <StyledTranslation
              key={locale}
              color={percents_color}
              onClick={() => onLocaleClick(locale)}
              isActive={locale === selectedLocale}
              disabled={disabled}
            >
              <StyledTranslationCountry className="TranslationCountry">
                <StyledIconFlag className="IconFlag">
                  {getFlag(locale)}
                </StyledIconFlag>
                <span>{locale}</span>
              </StyledTranslationCountry>
              <span>{percents}%</span>
            </StyledTranslation>
          );
        })}
      </StyledTranslations>
    </StyledTranslationWrapper>
  );
};
