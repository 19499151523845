import { merge } from 'lodash';
import { FC, useMemo } from 'react';
import {
  ThemeProvider as ReactUiKitThemeProvider,
  ThemeProvider,
  createTheme,
} from 'react-ui-kit-exante';

import { theme } from './index';

export const ComposedThemeProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const brandingTheme = window.NOTIFICATIONS_SHAPER_UI.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return theme;
    }

    return createTheme(merge({}, theme, brandingTheme));
  }, [brandingTheme]);

  return (
    <ThemeProvider theme={themeValue}>
      <ReactUiKitThemeProvider theme={themeValue}>
        {children}
      </ReactUiKitThemeProvider>
    </ThemeProvider>
  );
};
