import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ExternalAccounts = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 17h6M28 14v6M13.5 20a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM3 25c2.569-3.056 6.195-5 10.5-5s7.931 1.944 10.5 5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M31 17h-6M28 20v-6M13.5 18a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM3 25c2.569-3.056 6.195-5 10.5-5s7.931 1.944 10.5 5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M31 17h-6M28 20v-6M13.5 18a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM3 25c2.569-3.056 6.195-5 10.5-5s7.931 1.944 10.5 5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M25 17h6M28 14v6M13.5 20a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM3 25c2.569-3.056 6.195-5 10.5-5s7.931 1.944 10.5 5" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ExternalAccounts;
