import { styled, FormLayout } from 'react-ui-kit-exante';

export const StyledTemplateHeaderWrapper = styled(FormLayout)`
  padding: 24px 0;
  gap: 24px;
  align-items: center;
`;

export const StyledTemplateHeaderInfo = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const StyledTemplateHeaderActions = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: auto;
`;

export const StyledTemplateHeaderTitle = styled('h2')`
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
`;
