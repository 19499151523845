import axios, { CancelTokenSource } from 'axios';
import { useRef, useState } from 'react';

export const useRequestAborting = () => {
  const [abortingLoading, setAbortingLoading] = useState(false);
  const cancelTokenRef = useRef<CancelTokenSource>();

  const newCancelToken = () => {
    cancelTokenRef.current = axios.CancelToken.source();
    return cancelTokenRef.current.token;
  };

  const onSetAbortingLoading = (value: boolean) => {
    setAbortingLoading(value);
  };

  return {
    abortingLoading,
    cancelTokenRef,
    newCancelToken,
    onSetAbortingLoading,
  };
};
