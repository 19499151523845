import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Key = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.646 15.353a9 9 0 115 5L15 22h-3v3H9v3H4v-5l7.646-7.647z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5 11a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            fill="currentColor"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.774 16.615a9 9 0 116.123 3.535l-1.164 2.016-2.898.777.777 2.898-2.898.776.777 2.898-4.83 1.294-1.294-4.83 5.407-9.364z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.424 12.825a1.5 1.5 0 10-.777-2.898 1.5 1.5 0 00.777 2.898z"
            fill="currentColor"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.774 16.615a9 9 0 116.123 3.535l-1.164 2.016-2.898.777.777 2.898-2.898.776.777 2.898-4.83 1.294-1.294-4.83 5.407-9.364z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.424 12.825a1.5 1.5 0 10-.777-2.898 1.5 1.5 0 00.777 2.898z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4}>
            <path
              d="M11.646 15.353a9 9 0 115 5L15 22h-3v3H9v3H4v-5l7.646-7.647z"
              stroke="currentColor"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5 11a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Key;
