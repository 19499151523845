import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';

import type { ISortBy } from 'Components/Table/types';

import type { TGetDefaultSorting } from '../types';

export interface IUseSortingProps {
  getDefaultSorting?: TGetDefaultSorting;
}

const DEFAULT_SORTING_PARAMS: ISortBy[] = [];

export function useSorting({ getDefaultSorting }: IUseSortingProps) {
  const [sorting, setSorting] = useState(() =>
    getDefaultSorting ? getDefaultSorting() : DEFAULT_SORTING_PARAMS,
  );

  const handleSortingChange = useCallback((newSorting) => {
    setSorting((prevSorting) => {
      if (isEqual(prevSorting, newSorting)) {
        return prevSorting;
      }

      return newSorting;
    });
  }, []);

  return { sorting, setSorting: handleSortingChange };
}

export type TUseSortingResult = ReturnType<typeof useSorting>;
