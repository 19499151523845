import type { TExtendedColumnWithAdditionalFields } from '../types';

import { prepareValueForOption } from './prepareValueForOption';

export const getAvailableOptions = <Data extends object>(
  data: Data[],
  column: Partial<TExtendedColumnWithAdditionalFields<Data>>,
  separator: string,
) => {
  if (column.type?.toLowerCase().includes('select')) {
    return Array.from(
      new Set(
        data.reduce<string[]>((acc, currentValue) => {
          const id = column.id as string;

          if (currentValue[id]) {
            const rowValues = prepareValueForOption(currentValue[id], separator)
              .replace(/\s+/g, '')
              .split(separator);
            return acc.concat(rowValues);
          }
          return acc;
        }, []),
      ),
    );
  }

  return undefined;
};
