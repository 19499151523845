import { animated } from 'react-spring';
import { styled } from 'react-ui-kit-exante';

export const StyledTemplateHistory = styled(animated.div)(({ theme }) => ({
  border: `1px solid ${theme?.color?.line?.primary}`,
  position: 'fixed',
  bottom: 0,
  zIndex: 9998,
  boxSizing: 'border-box',
}));

export const StyledEmptyHistory = styled('div')(({ theme }) => ({
  background: theme?.color?.bg?.primary,
  minHeight: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TemplateHistoryTableContainer = styled('div')`
  overflow-y: scroll;
  max-height: 360px;
`;
